import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ThemeContext, themes } from 'sc-context';
import styles from './divider.module.scss';

class Divider extends React.Component {
  render() {
    const { isTruncated, isHidden, extra } = this.props;
    const { theme } = this.context;
    const rootClasses = classNames(styles.root, {
      [styles[theme]]: theme !== themes.default,
      [styles.isHidden]: isHidden,
    });

    if (isTruncated) {
      const lineClasses = classNames(styles.line, {
        [styles[theme]]: theme !== themes.default,
      });

      return <div className={lineClasses} {...extra} />;
    }

    return <div className={rootClasses} data-width="17" {...extra} />;
  }
}

Divider.contextType = ThemeContext;

Divider.propTypes = {
  /**
   * Флаг, обозначающий, что компонент находится в выпадающем списке тулбара
   */
  isTruncated: PropTypes.bool,
  /**
   * Скрытие компонента. Необходимо для выпадающего списка в тулбаре
   */
  isHidden: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Divider;
