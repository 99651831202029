import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ThemeContext, themes } from 'sc-context';
import { LMS } from '@sc-reactkit/internal';
import styles from './logo.module.scss';

export default class Logo extends React.Component {
  render() {
    const { children, className, style, size, extra } = this.props;
    const { theme, size: contextSize } = this.context;

    const rootClasses = classNames(styles.root, [styles[size || contextSize]], {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    return (
      <div className={rootClasses} style={style} role="button" tabIndex={0} {...extra}>
        {children}
      </div>
    );
  }
}

Logo.contextType = ThemeContext;

Logo.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Размер
   */
  size: LMS,
};
