import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import { LMS } from '@sc-reactkit/internal';

import styles from './level.module.scss';

export default class Level extends React.Component {
  render() {
    const { children, size, className, style, extra } = this.props;
    const { theme, size: contextSize } = this.context;
    const rootClasses = classNames(styles.root, [styles[size || contextSize]], {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    return (
      <div className={rootClasses} style={style} {...extra}>
        {children}
      </div>
    );
  }
}

Level.contextType = ThemeContext;

Level.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Размер
   */
  size: LMS,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
