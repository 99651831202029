import React from 'react';
import classNames from 'classnames';

import { SkeletonBar } from '@sc-reactkit/helpers';
import { ThemeContext, themes } from 'sc-context';

import styles from './text.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

export type TextColorType =
  | 'default'
  | 'gray'
  | 'white'
  | 'red'
  | 'green'
  | 'yellow'
  | 'black'
  | 'moderateGray'
  | 'lightGray';

export interface ITextProps extends IExtra, IExtendClassProps {
  /**
   * Полужирное начертание
   */ bold?: boolean;
  /**
   * Малый размер шрифта
   */
  small?: boolean;
  /**
   * Одиночный текст (отсутствует отступ снизу)
   */
  single?: boolean;
  /**
   * skeleton-поведение
   */
  isSkeleton?: boolean;
  /**
   * Цвет
   */
  color?: TextColorType;
  /**
   * Значение атрибута data-component. Необходимо для тестирования, а также для доступа при написании стиоей из
   * вышестоящих компонентов
   */
  dataComponent?: string;
}

const Text: React.FC<ITextProps> = props => {
  const {
    children,
    bold = false,
    small = false,
    color = 'default',
    dataComponent = 'text',
    single,
    className,
    style,
    isSkeleton,
    extra,
  } = props;
  const { theme = themes.default } = React.useContext(ThemeContext);
  const textClasses = classNames(styles.root, {
    [styles.bold]: bold,
    [styles.small]: small,
    [styles.single]: single,
    [styles[color]]: color,
    [styles[theme]]: theme !== themes.default,
    [String(className)]: className,
    [styles.skeleton]: isSkeleton,
  });

  return (
    <p className={textClasses} style={style} data-component={dataComponent} {...extra}>
      {isSkeleton ? <SkeletonBar extraClasses={styles.textSkeletonClasses} /> : children}
    </p>
  );
};

export default Text;
