import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';

import styles from './header.module.scss';

/**
 * Глобальная панель инструментов
 */
class Header extends React.Component {
  render() {
    const { className, style, extra } = this.props;
    const { theme } = this.context;
    const rootClasses = classNames(styles.root, {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    return (
      <div className={rootClasses} style={style} {...extra}>
        {this.props.children}
      </div>
    );
  }
}

Header.contextType = ThemeContext;

Header.propTypes = {
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
};

export default Header;
