import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import deepEqual from 'deep-equal';
import nanoid from 'nanoid';

import styles from './base-tabs.module.scss';

const defaultTabComponentWrapper = ({ titlesClassName, children }) => {
  const titleClasses = classNames(styles.baseTabsTitles, {
    [Array.isArray(titlesClassName) ? titlesClassName.join(' ') : titlesClassName]:
      titlesClassName && titlesClassName.length,
  });

  return <div className={titleClasses}>{children}</div>;
};

defaultTabComponentWrapper.propTypes = {
  /**
   * Классы для контейнера тайлов
   */
  titlesClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

class BaseTabs extends React.Component {
  state = {
    activeTabIndex: this.props.activeTabIndex,
    data: this.props.data,
    uuidMemo: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!deepEqual(nextProps.data, prevState.data) || prevState.uuidMemo.length === 0) {
      const { data } = nextProps;
      return {
        data,
        uuidMemo: Array(data.length)
          .fill(0)
          .map(() => nanoid()),
      };
    }

    return null;
  }

  onTabTitleClick = idx => () => {
    const { onTabClick } = this.props;
    this.setState({ activeTabIndex: idx });
    onTabClick(idx);
  };

  render() {
    const {
      tabComponent: TabComponent,
      tabComponentWrapper: TabComponentWrapper = defaultTabComponentWrapper,
      tabProps,
      activeProp,
      data,
      contentField,
      titleField,
      titlesClassName,
      contentClassName,
      isWrapContent,
      extra,
    } = this.props;
    const { activeTabIndex, uuidMemo } = this.state;

    const tabTitles = data.map((item, idx) => (
      <TabComponent
        key={uuidMemo[idx]}
        onClick={this.onTabTitleClick(idx)}
        {...{ [activeProp]: idx === activeTabIndex }}
        {...tabProps}
      >
        {item[titleField]}
      </TabComponent>
    ));

    const isActiveTab = data[activeTabIndex] && data[activeTabIndex][contentField];

    const wrapContent = content =>
      isActiveTab && isWrapContent ? <div className={contentClassName}>{content}</div> : content;

    return (
      <>
        <TabComponentWrapper titlesClassName={titlesClassName} extra={extra}>
          {tabTitles}
        </TabComponentWrapper>
        {wrapContent(data[activeTabIndex][contentField])}
      </>
    );
  }
}

BaseTabs.defaultProps = {
  activeProp: 'isActive',
  activeTabIndex: 0,
  contentField: 'content',
  titleField: 'title',
  onTabClick: () => {},
  isWrapContent: true,
  data: [],
};

BaseTabs.propTypes = {
  /**
   * Имя пропса, в которое передаётся активность таба
   */
  activeProp: PropTypes.string,
  /**
   * Индекс активного таба
   */
  activeTabIndex: PropTypes.number,
  /**
   * Классы для контейнера тайлов
   */
  titlesClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Класс для контейнера контента
   */
  contentClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Массив с данными
   */
  data: PropTypes.arrayOf(PropTypes.any),
  /**
   * Компонент для отображения таба
   */
  tabComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * Компонент, в который будут обёрнуты все табы
   */
  tabComponentWrapper: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * Пропсы, которые прокидываются в компонент таба
   */
  tabProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Имя поля в объекте, из которого берутся данные для тайтла табов
   */
  titleField: PropTypes.string,
  /**
   * Имя поля в объекте, из которого берутся данные для контента
   */
  contentField: PropTypes.string,
  /**
   * Коллбек по клику на таб. Принимает индекс таба, по которому был произведён клик
   */
  onTabClick: PropTypes.func,
  /**
   * Оборачивать или нет контент таба в дополнительный div
   */
  isWrapContent: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default BaseTabs;
