import React from 'react';
import classNames from 'classnames';

import { SkeletonBar } from '@sc-reactkit/helpers';
import { ThemeContext, themes } from 'sc-context';
import Icon, { IconSourceType, IconsPackType } from '@sc-reactkit/icon';

import styles from './button.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import ISpread from '@sc-reactkit/internal/interfaces/spread';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

export type ButtonKind = 'regular32' | 'regular40' | 'dialog' | 'simple' | 'link' | 'icon' | 'bar';
export type ButtonColor =
  | 'default'
  | 'accent'
  | 'green'
  | 'blue'
  | 'yellow'
  | 'red'
  | 'violet'
  | 'regular'
  | 'gray'
  | 'chroma';
export type ButtonSize = 'big' | 'regular' | 'small';
export type ButtonType = 'submit' | 'button' | 'reset';

export interface IButtonProps extends IExtra, ISpread, IExtendClassProps {
  /**
   * Тип кнопки
   */
  kind?: ButtonKind | 'regular';
  /**
   * Акцентная кнопка
   */
  accent?: boolean;
  /**
   * Цвет кнопки
   */
  color?: ButtonColor;
  /**
   * Кнопка растягивается на всю ширину родителя
   */
  wide?: boolean;
  /**
   * Маленькая «табличная» кнопка
   */
  small?: boolean;
  /**
   * Кнопка неактивна
   */
  disabled?: boolean;
  /**
   * Тип кнопки (см. атрибут html-элемента button). Значение по умолчанию - submit
   */
  type?: ButtonType;
  /**
   * Иконка кнопки
   */
  icon?: string | React.ComponentType | JSX.Element;
  /**
   * Положение иконки
   */
  iconPosition?: string;
  /**
   * Размер кнопки-иконки
   */
  iconButtonSize?: ButtonSize;
  /**
   * Пак иконок для кнопки-иконки типа regular
   */
  iconSource?: IconSourceType;
  /**
   * skeleton-поведение
   */
  isSkeleton?: boolean;
  /**
   * Прозрачный фон default состояния кнопок-иконок
   */
  isTransparent?: boolean;
  /**
   * Цвет иконки
   */
  iconColor?: string;
  /**
   * Обработчик клика
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Состояние hover
   */
  isHover?: boolean;
  /**
   * Состояние pressed\active
   */
  isActive?: boolean;
  /**
   * Состояние focus
   */
  isFocus?: boolean;
  /**
   * Состояние enable для icon-кнопок
   */
  isEnabled?: boolean;
  /**
   * Пак иконок
   */
  iconsPack?: IconsPackType;
}

/**
 * Кнопка
 */
const Button: React.FC<IButtonProps> = props => {
  const {
    accent,
    kind = 'regular32',
    iconButtonSize = 'regular',
    color = 'default',
    type = 'submit',
    children,
    small,
    disabled,
    onClick,
    size,
    className,
    icon,
    iconPosition,
    iconSource,
    iconsPack,
    iconColor,
    isSkeleton,
    isTransparent,
    style,
    wide,
    extra,
    isHover,
    isActive,
    isFocus,
    isEnabled,
    ...other
  } = props;

  const { theme = themes.default } = React.useContext(ThemeContext);
  const node = React.useRef<HTMLButtonElement>(null);

  const buttonKind = kind === 'regular' ? 'regular32' : kind;
  if (kind === 'regular') {
    console.warn(
      `Prop 'kind' with value 'regular' in 'Button' component will be deprecated in following versions.
         Please use 'regular32' instead.`,
    );
  }

  const buttonColor = accent ? 'accent' : color;
  const buttonClasses = classNames(styles[buttonKind], styles[size], styles[buttonColor], {
    [styles.wide]: wide,
    [styles.table]: small,
    [styles.disabled]: disabled,
    [styles[theme]]: theme !== themes.default,
    [String(className)]: className,
    [styles.transparent]: isTransparent,
    [styles.hasIcon]: icon,
    [styles[iconButtonSize]]: kind === 'icon' && iconButtonSize,
    [styles.iconRight]: iconPosition === 'right',
    isHover,
    isActive,
    isFocus,
    [styles.isEnabled]: isEnabled,
  });

  const getIconElement = () => {
    // для случая, если нужно получить иконку для kind = icon
    if (kind === 'icon' && typeof icon === 'string') {
      switch (iconButtonSize) {
        case 'big':
          return <Icon name={icon} disabled={disabled} source="icons24" iconsPack={iconsPack} color={iconColor} />;
        case 'small':
          return <Icon name={icon} disabled={disabled} size={8} iconsPack={iconsPack} color={iconColor} />;
        default:
          return <Icon name={icon} disabled={disabled} source={iconSource} iconsPack={iconsPack} color={iconColor} />;
      }
    }
    // для использования в обычных кнопках, типа regular
    if (typeof icon === 'string') {
      return <Icon name={icon} disabled={disabled} size={16} iconsPack={iconsPack} color={iconColor} />;
    }
    return icon;
  };

  const IconElement = getIconElement();

  const isString = typeof children === 'string';

  if (isSkeleton) {
    return (
      <button className={buttonClasses} type="button" {...other} {...extra}>
        <SkeletonBar />
      </button>
    );
  }

  const kindIconRe = /icon/gi;
  const isIcon = kindIconRe.test(kind);

  /* eslint-disable react/button-has-type */
  return (
    <button
      ref={node}
      className={buttonClasses}
      onClick={onClick}
      style={style}
      type={type}
      disabled={disabled}
      {...other}
      {...extra}
    >
      {isEnabled && <div className={styles.isEnableOverlay} />}
      {icon && <div className={classNames(styles.row, styles.iconWrapper)}>{IconElement}</div>}
      {children && !isIcon && (
        <div title={isString ? children : undefined} className={styles.text}>
          {children}
        </div>
      )}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

export default Button;
