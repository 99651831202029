import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes, sizes } from 'sc-context';
import { ListItem, ListItemContainer, ListItemText } from '@sc-reactkit/list';
import Icon from '@sc-reactkit/icon';

import Arrow from './sub-components/arrow';

import styles from './button.module.scss';

/**
 * Кнопка действий, используется в глобальных и локальных панелях инструментов
 */
class TButton extends React.Component {
  node = React.createRef();

  onClick = event => !this.props.disabled && this.props.onClick(event);

  /**
   * Любой элемент тулбара, который может быть скрыт в дропдаун вследствии того, что не помещается по ширине, должен
   * по пропсу isTruncated возвращать альтернативный вид отображения в дропдауне
   * @returns {JSX.Element}
   */
  getTruncatedView = () => {
    const { label, icon, disabled, truncatedConfig, iconsPack, extra } = this.props;
    const { onClick } = this;

    const iconComponent = typeof icon === 'string' ? <Icon name={icon} iconsPack={iconsPack} source="icons24" /> : icon;

    return (
      <ListItem onClick={onClick} disabled={disabled} extra={extra}>
        {truncatedConfig.hasIcon && (
          <ListItemContainer onClick={onClick} className={styles.truncatedIconButton}>
            {iconComponent}
          </ListItemContainer>
        )}
        <ListItemText primary={truncatedConfig.label || label || ''} />
      </ListItem>
    );
  };

  render() {
    const {
      label,
      icon,
      image,
      caret,
      selected,
      disabled,
      children,
      className,
      hasStatus,
      statusColor,
      isTruncated,
      isHidden,
      style,
      iconsPack,
      extra,
    } = this.props;
    const { onClick } = this;
    const { theme, size } = this.context;
    const buttonClasses = classNames(styles.root, {
      [styles.selected]: selected,
      [styles.disabled]: disabled,
      [styles[theme]]: theme !== themes.default,
      [styles[size]]: size !== sizes.medium,
      [className]: className,
    });
    const iconElement =
      typeof icon === 'string' ? (
        <div className={styles.icon}>
          <Icon
            name={icon}
            source="icons24"
            iconsPack={iconsPack}
            disabled={disabled}
            hasStatus={hasStatus}
            statusColor={statusColor}
          />
        </div>
      ) : (
        <div className={styles.icon}>{icon && React.cloneElement(icon, { disabled })}</div>
      );
    const labelClasses = classNames(styles.label);

    // Компонент находится в дропдауне и не поместился по ширине. Возвращаем альтернативный вид
    if (isTruncated) {
      return this.getTruncatedView();
    }

    const wrapperClasses = classNames(styles.wrapper, {
      [styles.isHidden]: isHidden,
    });

    return (
      <div className={wrapperClasses} {...extra}>
        <button
          className={buttonClasses}
          onKeyPress={onClick}
          onClick={onClick}
          type="button"
          tabIndex={0}
          ref={this.node}
          style={style}
        >
          {icon && iconElement}
          {image && <div className={styles.image}>{image}</div>}
          {label && <span className={labelClasses}>{label}</span>}
          {children && <div className={styles.children}>{children}</div>}
          {caret && <Arrow theme={theme} disabled={disabled} />}
        </button>
      </div>
    );
  }
}

TButton.contextType = ThemeContext;

TButton.defaultProps = {
  onClick: () => null,
  caretDirection: 'down',
  caret: false,
  selected: false,
  truncatedConfig: {
    hasIcon: true,
  },
};

TButton.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Текст кнопки
   */
  label: PropTypes.string,
  /**
   * Иконка
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Пак иконок
   */
  iconsPack: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Картинка перед заголовком
   */
  image: PropTypes.element,
  /**
   * Стрелка выпадающего списка
   */
  caret: PropTypes.bool,
  /**
   * Направление стрелки
   */
  caretDirection: PropTypes.oneOf(['up', 'down']),
  /**
   * Функция-обработчик клика
   */
  onClick: PropTypes.func,
  /**
   * Выбран ли элемент
   */
  selected: PropTypes.bool,
  /**
   * Кнопка неактивна
   */
  disabled: PropTypes.bool,
  /**
   * Наличие статуса у иконки
   */
  hasStatus: PropTypes.bool,
  /**
   * Цвет статуса
   */
  statusColor: PropTypes.string,
  /**
   * Кнопка малого размера
   */
  small: PropTypes.bool,
  /**
   * Флаг, обозначающий, что компонент находится в выпадающем списке тулбара
   */
  isTruncated: PropTypes.bool,
  /**
   * Настройка отображения TButton в выпадающем меню
   */
  truncatedConfig: PropTypes.shape({
    label: PropTypes.string,
    hasIcon: PropTypes.bool,
  }),
  /**
   * Скрытие компонента. Необходимо для выпадающего списка в тулбаре
   */
  isHidden: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default TButton;
