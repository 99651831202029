import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@sc-reactkit/icon';
import { ThemeContext, themes } from 'sc-context';
import { ErrorMessages } from '@sc-reactkit/form-messages';

import { SwitchBase } from '@sc-reactkit/internal';
import styles from './switch.module.scss';

const getIconComponent = (icon, className, disabled, iconDisabledColor, iconsSettings) =>
  typeof icon === 'string' ? (
    <div className={className}>
      <Icon name={icon} color={disabled && iconDisabledColor} {...iconsSettings} />
    </div>
  ) : (
    <div className={className}>{icon}</div>
  );

/**
 * Поле выбора Switch
 */
class Switch extends React.Component {
  render() {
    const {
      small,
      errors,
      size,
      disabled,
      label,
      className,
      style,
      isSkeleton,
      children,
      iconLeft,
      iconRight,
      iconToggle,
      iconsSettings,
      extra,
      isFocus,
      isError,
      isDisabled,
      ...other
    } = this.props;
    const { theme } = this.context;

    // Размеры, заданные строкой преобразуем в числовое значение.
    let actualSize = size;
    if (typeof size === 'string' || small) {
      console.warn('Please use number value prop for size instead string.');
      actualSize = 24;
      if (size === 'medium') {
        actualSize = 40;
      }
      if (size === 'large') {
        actualSize = 64;
      }
    }

    if (disabled) {
      console.warn('Please use isDisabled prop instead disabled. "disabled" prop will be removed in next versions');
    }
    // TODO: оставить только isDisabled, когда уберём disabled
    const currentDisabled = disabled || isDisabled;

    const canIcons = actualSize === 64;
    const hasIcons = iconLeft || iconRight || iconToggle;
    const iconDisabledColor = theme !== themes.default ? '#393939' : '#a8a8a8';

    const customClasses = classNames(styles.label, {
      [styles[theme]]: theme !== themes.default,
    });

    const rootClasses = classNames(styles.root, {
      [styles[theme]]: theme !== themes.default,
      [styles.isDisabled]: currentDisabled,
      [styles.isFocus]: isFocus,
      [styles.hasIcons]: hasIcons,
      [styles.isError]: isError || errors,
      [styles[`size-${actualSize}`]]: actualSize,
      [styles.isSkeleton]: isSkeleton,
      [className]: className,
    });

    const iconToggleComponent =
      typeof iconLeft === 'string' ? <Icon name={iconToggle} color="white" {...iconsSettings} /> : iconToggle;

    if (isSkeleton) {
      return (
        <div className={rootClasses} style={style} {...extra}>
          <div className={styles.label} />
        </div>
      );
    }

    return (
      <>
        <div className={rootClasses} style={style} {...extra}>
          <SwitchBase
            inputType="checkbox"
            styles={styles}
            customClasses={customClasses}
            disabled={currentDisabled}
            {...other}
          >
            {canIcons && getIconComponent(iconLeft, styles.iconLeft, currentDisabled, iconDisabledColor, iconsSettings)}
            {canIcons &&
              getIconComponent(iconRight, styles.iconRight, currentDisabled, iconDisabledColor, iconsSettings)}
            <div className={styles.toggleContainer}>
              {children}
              {!children && <div className={styles.toggleItem}>{canIcons && iconToggleComponent}</div>}
            </div>
          </SwitchBase>
          {label && <div className={styles.text}>{label}</div>}
        </div>
        {errors && errors.length && <ErrorMessages errors={errors} className={styles.errors} />}
      </>
    );
  }
}

Switch.contextType = ThemeContext;

Switch.defaultProps = {
  defaultChecked: false,
  size: 40,
};

Switch.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Ключ модели
   */
  name: PropTypes.string,
  /**
   * Статус переключателя. Указывается, если необходимо, чтобы значением переключателя управлял внешний компонент
   */
  checked: PropTypes.bool,
  /**
   * Текст ошибки (ошибок)
   */
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Статус переключателя на момент создания компонента
   */
  defaultChecked: PropTypes.bool,
  /**
   * Поле недоступно
   */
  disabled: PropTypes.bool,
  /**
   * Обработчик нажатия на переключатель
   */
  onChange: PropTypes.func,
  /**
   * Заголовок
   */
  label: PropTypes.string,
  /**
   * Размер
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * skeleton-поведение
   */
  isSkeleton: PropTypes.bool,
  /**
   * Switch малого размера, лучше использовать атрибут size
   */
  small: PropTypes.bool,
  /**
   * Левая иконка переключателя
   */
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Правая иконка переключателя
   */
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Иконка на переключателе
   */
  iconToggle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Объект с настройками для иконок. Распаковывается сразу на все иконки
   * пропсов iconLeft / iconRight / iconToggle
   */
  iconsSettings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Пропс для программной установки состояния фокуса
   */
  isFocus: PropTypes.bool,
  /**
   * Пропс для программной установки состояния ошибки
   */
  isError: PropTypes.bool,
  /**
   * Пропс для программной установки состояния недоступности
   */
  isDisabled: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Switch;
