import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'sc-context';

import styles from './modal.module.scss';

class ModalContent extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    extra: PropTypes.object,
  };

  render() {
    const { children, extra } = this.props;

    return (
      <div className={styles.modalContent} {...extra}>
        {children}
      </div>
    );
  }
}

ModalContent.contextType = ThemeContext;

export default ModalContent;
