import React from 'react';
import classNames from 'classnames';

import { SkeletonBar } from '@sc-reactkit/helpers';
import { ThemeContext, themes } from 'sc-context';

import styles from './heading.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

export interface IHeadingProps extends IExtra, IExtendClassProps {
  /**
   * Акцентный закголовок
   */
  accent?: boolean;
  /**
   * Одиночный текст (отсутствует отступ снизу)
   */
  single?: boolean;
  /**
   * skeleton-поведение
   */
  isSkeleton?: boolean;
  /**
   * Title для текста
   */
  title?: string;
}

const Heading: React.FC<IHeadingProps> = props => {
  const { children, accent, single, className, style, isSkeleton, title, extra } = props;
  const { theme = themes.default } = React.useContext(ThemeContext);
  const headingClasses = classNames(styles.root, {
    [styles.accent]: accent,
    [styles.single]: single,
    [styles[theme]]: theme !== themes.default,
    [String(className)]: className,
    [styles.skeleton]: isSkeleton,
  });
  return (
    <h1 className={headingClasses} style={style} title={title} {...extra}>
      {isSkeleton ? <SkeletonBar extraClasses={styles.headingSkeletonStyles} /> : children}
    </h1>
  );
};

export default Heading;
