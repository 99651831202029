import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import styles from './skeleton.module.scss';

export default class SkeletonBar extends React.Component {
  render() {
    const { extraStyles, extraClasses, extra } = this.props;
    const { theme } = this.context;

    const rootClasses = classNames(styles.slider, extraClasses, {
      [styles[theme]]: theme !== themes.default,
    });

    return (
      <div className={rootClasses} style={extraStyles} {...extra}>
        <div className={styles.bar} />
      </div>
    );
  }
}

SkeletonBar.contextType = ThemeContext;

SkeletonBar.propTypes = {
  /**
   * Кастомные классы
   */
  extraClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Кастомные стили
   */
  extraStyles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
