import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext } from 'sc-context';

import { BaseDropdown, DropdownToggle, DropdownContent } from '@sc-reactkit/base-dropdown';

import { ListItem, ListItemContainer, ListItemText } from '@sc-reactkit/list';
import Icon from '@sc-reactkit/icon';

import Button from './button';
import Arrow from './sub-components/arrow';
import styles from './dropdown.module.scss';

const getFloatWrapperConfig = ({ isLeft, offset }) => ({
  nodeVertical: 'top',
  nodeHorizontal: isLeft ? 'left' : 'right',
  modalVertical: 'top',
  modalHorizontal: isLeft ? 'right' : 'left',
  offsetLeft: isLeft ? -offset : offset,
  isCheckBorders: false,
});

const defaultTruncatedConfig = {
  hasIcon: true,
  isLeft: true,
  offset: 12,
};

class TDropdown extends PureComponent {
  state = {
    actionListIsVisible: false,
  };

  /**
   * Любой элемент тулбара, который может быть скрыт в дропдаун вследствии того, что не помещается по ширине, должен
   * по пропсу isTruncated возвращать альтернативный вид отображения в дропдауне
   * @returns {JSX.Element}
   */
  getTruncatedView = () => {
    const {
      label,
      icon,
      children,
      disabled,
      isDisabled,
      closeByOutsideClick,
      onToggle,
      truncatedConfig,
      iconsPack,
      extra,
    } = this.props;
    const { theme } = this.context;

    return (
      <ListItem disabled={disabled} extra={extra}>
        <BaseDropdown
          closeByOutsideClick={closeByOutsideClick}
          closeByContentClick={false}
          onToggle={onToggle}
          floatWrapperProps={getFloatWrapperConfig({ ...defaultTruncatedConfig, ...truncatedConfig })}
          extra={extra}
          isDisabled={disabled || isDisabled}
          className={styles.truncatedDropdown}
        >
          <DropdownToggle className={styles.truncatedToggle}>
            {truncatedConfig.hasIcon && icon && (
              <ListItemContainer>
                <Icon name={icon} source="icons24" iconsPack={iconsPack} />
              </ListItemContainer>
            )}
            <ListItemText primary={truncatedConfig.label || label || ''} />
            <ListItemContainer>
              <Arrow theme={theme} />
            </ListItemContainer>
          </DropdownToggle>
          <DropdownContent>{children}</DropdownContent>
        </BaseDropdown>
      </ListItem>
    );
  };

  render() {
    const {
      label,
      icon,
      caret,
      children,
      image,
      tButtonChildren,
      disabled,
      isDisabled,
      className,
      style,
      listClassName,
      listStyle,
      closeByOutsideClick,
      baseDropdownProps,
      onToggle,
      isTruncated,
      isTruncateWrapper,
      isHidden,
      iconsPack,
      extra,
      ...other
    } = this.props;
    const { actionListIsVisible } = this.state;
    const rootClasses = classNames(styles.root, {
      [className]: className,
    });

    const caretDirection = actionListIsVisible ? 'up' : 'down';

    // Компонент находится в дропдауне и не поместился по ширине. Возвращаем альтернативный вид
    if (isTruncated) {
      return this.getTruncatedView();
    }

    // Для TDropdown, который скрывает непоместившиеся элементы запрещаем закрытие по клику внутри окна контента.
    // Необходимо для того, чтобы корректно отрабатывали клики по внутренним TDropdown.
    const extraProps = isTruncateWrapper ? { closeByContentClick: false } : undefined;

    const wrapperClasses = classNames(styles.wrapper, {
      [styles.isHidden]: isHidden,
    });

    return (
      <div className={wrapperClasses} {...extra}>
        <BaseDropdown
          closeByOutsideClick={closeByOutsideClick}
          onToggle={onToggle}
          floatWrapperProps={{
            ...baseDropdownProps,
            ...{ nodeVertical: 'bottom', nodeHorizontal: 'right', modalVertical: 'top', modalHorizontal: 'right' },
          }}
          extra={extra}
          isDisabled={disabled || isDisabled}
          {...other}
          {...extraProps}
        >
          <DropdownToggle>
            <div className={rootClasses} style={style}>
              <Button
                icon={icon}
                iconsPack={iconsPack}
                label={label}
                image={image}
                caret={caret}
                caretDirection={caretDirection}
                selected={actionListIsVisible}
                disabled={disabled || isDisabled}
                extra={{ 'data-anchor': 'dropdown-button' }}
              >
                {tButtonChildren}
              </Button>
            </div>
          </DropdownToggle>
          <DropdownContent>{children}</DropdownContent>
        </BaseDropdown>
      </div>
    );
  }
}

TDropdown.contextType = ThemeContext;

TDropdown.defaultProps = {
  closeByOutsideClick: true,
  closeByContentClick: true,
  closeBySelfClick: true,
  caret: true,
  truncatedConfig: {},
  onToggle: () => {},
};

TDropdown.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Кастомный инлайн-стиль
   */
  listStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  listClassName: PropTypes.string,
  /**
   * Произвольный потомок для TButton
   */
  tButtonChildren: PropTypes.element,
  /**
   * Иконка
   */
  icon: PropTypes.string,
  /**
   * Пак иконок
   */
  iconsPack: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Картинка
   */
  image: PropTypes.element,
  /**
   * Заголовок
   */
  label: PropTypes.string,
  /**
   * Нужна ли каретка
   */
  caret: PropTypes.bool,
  /**
   * Кнопка недоступна
   */
  disabled: PropTypes.bool,
  /**
   * Кнопка недоступна
   */
  isDisabled: PropTypes.bool,
  /**
   * Коллбек на открытие/закрытие дропдауна
   */
  onToggle: PropTypes.func,
  /**
   * Закрытие выпадающего окна по клику вне таргета
   */
  closeByOutsideClick: PropTypes.bool,
  /**
   * Закрытие выпадающего окна по клику на само окно
   */
  closeByContentClick: PropTypes.bool,
  /**
   * Закрытие выпадающего окна по клику на сам элемент
   */
  closeBySelfClick: PropTypes.bool,
  /**
   * Объект с настройками для FloatWrapper
   */
  baseDropdownProps: PropTypes.object, // eslint-disable-line
  /**
   * Флаг, необходмый для определения TDropdown, который оборачивает непоместившиеся компоненты
   */
  isTruncateWrapper: PropTypes.bool,
  /**
   * Флаг, обозначающий, что компонент находится в выпадающем списке тулбара
   */
  isTruncated: PropTypes.bool,
  /**
   * Настройка отображения TDropdown в выпадающем меню
   */
  truncatedConfig: PropTypes.exact({
    label: PropTypes.string,
    hasIcon: PropTypes.bool,
    isLeft: PropTypes.bool,
  }),
  /**
   * Скрытие компонента. Необходимо для выпадающего списка в тулбаре
   */
  isHidden: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default TDropdown;
