import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import { LMS } from '@sc-reactkit/internal';
import CustomScroll from '@sc-reactkit/custom-scroll';

import { Hamburger, ArrowBack } from './assests/svg-items.js';

import styles from './menu.module.scss';

export default class Menu extends React.Component {
  static getDerivedStateFromProps(props) {
    return {
      location: props.location,
    };
  }

  state = {
    subMenuItems: [],
  };

  componentDidMount() {
    this.constructMenuFromLocation();
  }

  handleClick = (element, level = 0) => {
    const { defaultOnClick } = this.props;
    const { children, onClick, link } = element.props;

    const nextState = {
      location: link,
    };

    const nextSubMenuItems = [...this.state.subMenuItems];
    nextSubMenuItems.length = level;

    if (children) {
      nextSubMenuItems.push(element);
    }

    nextState.subMenuItems = nextSubMenuItems;

    this.setState({ ...nextState }, () => {
      if (onClick) {
        onClick(link);
      } else if (!children && !onClick) {
        defaultOnClick(link);
      }
    });
  };

  handleOnBack = level => () => {
    const nextSubMenuItems = [...this.state.subMenuItems];

    nextSubMenuItems.length = level - 1;
    const nextState = { subMenuItems: nextSubMenuItems };
    if (this.state.location) {
      nextState.location = '';
    }
    this.setState({ ...nextState });
  };

  constructMenuFromLocation() {
    const { location } = this.props;
    const subMenuItems = [];

    const findMenuItem = elements => {
      let element;
      React.Children.forEach(elements, child => {
        if (location.indexOf(child.props.link) === 0) {
          element = child;
        }
      });
      if (!element) {
        return;
      }
      const { children } = element.props;
      if (children) {
        subMenuItems.push(element);
        findMenuItem(children);
      }
    };
    findMenuItem(this.props.children);
    this.setState({ subMenuItems });
  }

  getIsActive = (location, link) => {
    const isSame = location === link;
    const isParentOfLink = location.indexOf(link) === 0 && location[link.length] === '/';
    return isSame || isParentOfLink;
  };

  renderMenuItems = children => {
    const { location, subMenuItems } = this.state;
    const { isCollapsed } = this.props;
    const level = subMenuItems.length;

    const hasActiveItemByProps = Array.isArray(children)
      ? children.some(item => item.props.isActive)
      : children.props.isActive;

    const newChildren = React.Children.map(children, child => {
      const {
        props: { link, isActive },
      } = child;
      const nextProps = {
        onClick: () => {
          this.handleClick(child, level);
        },
        isActive: hasActiveItemByProps ? isActive : this.getIsActive(location, link),
        hasChildren: Boolean(child.props.children),
        isCollapsed,
      };
      return React.cloneElement(child, { ...nextProps });
    });
    return <div className={styles.content}>{newChildren}</div>;
  };

  render() {
    const { subMenuItems } = this.state;
    const { headerOnClick, size, className, style, scrollBarProps, isCollapsed, extra } = this.props;
    const { theme, size: contextSize } = this.context;

    const level = subMenuItems.length;
    const source = level ? subMenuItems[subMenuItems.length - 1] : this;

    const rootClasses = classNames(styles.root, styles[size || contextSize], {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    const titleClasses = classNames(styles.title, {
      [styles.hasTitleBorderBottom]: headerOnClick,
    });

    const menuClasses = classNames(styles.menu, {
      [styles.hasMenuBorderTop]: headerOnClick,
    });

    // TODO: newThemeSvgReplace ArrowBack
    return (
      <div className={rootClasses} style={style} {...extra}>
        {headerOnClick && (
          <div className={titleClasses}>
            {headerOnClick && (
              <button
                className={styles.hamburger}
                onClick={headerOnClick}
                onKeyPress={headerOnClick}
                type="button"
                data-anchor="menu-button-hamburger"
              >
                <Hamburger theme={theme} />
              </button>
            )}
          </div>
        )}
        <div className={menuClasses}>
          <CustomScroll
            scrollBarProps={{ autoHeight: true, autoHeightMin: '100%', autoHeightMax: '100%', ...scrollBarProps }}
          >
            {level !== 0 && (
              <div
                className={styles.header}
                onClick={this.handleOnBack(level)}
                onKeyPress={this.handleOnBack(level)}
                tabIndex={0}
                role="button"
                data-anchor="menu-button-back"
              >
                <div className={styles.icon}>
                  <ArrowBack theme={theme} />
                </div>
                {!isCollapsed && <span className={styles.label}>{source.props.label}</span>}
              </div>
            )}
            {this.renderMenuItems(source.props.children)}
          </CustomScroll>
        </div>
      </div>
    );
  }
}

Menu.contextType = ThemeContext;

Menu.defaultProps = {
  defaultOnClick: () => {},
  isCollapsed: false,
};

Menu.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Текущий активный URL
   */
  location: PropTypes.string,
  /**
   * Компактный вид меню
   */
  isCollapsed: PropTypes.bool,
  /**
   * Пропсы для скроллбара
   */
  scrollBarProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Общий обработчик клика по элементу меню. Например метод react-router, который добавляет адрес в историю
   */
  defaultOnClick: PropTypes.func,
  headerOnClick: PropTypes.func,
  /**
   * Размер
   */
  size: LMS,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
