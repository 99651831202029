import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import Preloader from '@sc-reactkit/preloader';
import { LMS, escapeModalClose, zIndexService } from '@sc-reactkit/internal';

import styles from './modal.module.scss';

class Modal extends Component {
  state = {
    domNode: document.createElement('div'),
    isPressedOnOverlay: false,
  };

  componentDidMount() {
    document.body.appendChild(this.state.domNode);
    const { portalClassName, portalStyle } = this.props;
    this.state.domNode.className = styles.portal;
    if (portalClassName) {
      this.state.domNode.classList.add(portalClassName);
    }
    if (portalStyle) {
      Object.keys(portalStyle).forEach(prop => {
        this.state.domNode.style[prop] = portalStyle[prop];
      });
    }
    this.state.domNode.style.zIndex = zIndexService.addToComponentStore(this.state.domNode);
    const { onShow } = this.props;
    if (onShow) {
      onShow();
    }
  }

  componentWillUnmount() {
    zIndexService.removeToComponentStore(this.state.domNode);
    document.body.removeChild(this.state.domNode);
  }

  onCloseModalComponent() {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  onMouseDown = isPressedOnOverlay => event => {
    event.stopPropagation();
    this.setState({ isPressedOnOverlay });
  };

  onMouseUp = isPressOnOverlay => event => {
    event.stopPropagation();
    const { isBackdropClose, onClose } = this.props;
    const { isPressedOnOverlay } = this.state;
    if (isBackdropClose && isPressedOnOverlay && isPressOnOverlay) {
      onClose();
      this.setState({ isPressedOnOverlay: undefined });
    }
  };

  render() {
    const { children, style, className, size, isLoading, preloaderProps, extra } = this.props;
    const { theme, size: contextSize } = this.context;
    const rootClasses = classNames(styles.root, styles[size || contextSize], {
      [styles[theme]]: theme !== themes.default,
    });

    const contentClasses = classNames(styles.content, {
      [className]: className,
    });

    return ReactDOM.createPortal(
      <div
        className={rootClasses}
        onMouseDown={this.onMouseDown(true)}
        onMouseUp={this.onMouseUp(true)}
        role="button"
        tabIndex={0}
        {...extra}
      >
        <div
          className={styles.scroll}
          onMouseDown={this.onMouseDown(false)}
          onMouseUp={this.onMouseUp(false)}
          role="button"
          tabIndex={0}
        >
          <div className={contentClasses} style={style || {}}>
            {children}
            {isLoading && (
              <div className={styles.preloader}>
                <Preloader {...preloaderProps} />
              </div>
            )}
          </div>
        </div>
      </div>,
      this.state.domNode,
    );
  }
}

Modal.contextType = ThemeContext;

Modal.defaultProps = {
  isBackdropClose: false,
};

Modal.propTypes = {
  /**
   * Действия при закрытии
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Обработчик при открытии
   */
  onShow: PropTypes.func,
  /**
   * Закрывать ли по клику на подложку
   */
  isBackdropClose: PropTypes.bool,
  /**
   * Показывать индикатор загрузки
   */
  isLoading: PropTypes.bool,
  /**
   * Стили контентной части окна
   */
  style: PropTypes.objectOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
  /**
   * Класс для контентной части окна
   */
  className: PropTypes.string,
  /**
   * Кастомный инлайн-стиль для элемента портала
   */
  portalStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля для элемента портала
   */
  portalClassName: PropTypes.string,
  /**
   * Размер
   */
  size: LMS,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Произвольные атрибуты Preloader
   */
  preloaderProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default escapeModalClose(Modal);
