import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LMS } from '@sc-reactkit/internal';
import { SkeletonBar } from '@sc-reactkit/helpers';
import { ThemeContext, themes } from 'sc-context';

import styles from './hint-message.module.scss';

class HintMessage extends React.Component {
  render() {
    const { children, color, className, style, isSkeleton, title, extra } = this.props;
    const { size, theme } = this.context;

    const rootClasses = classNames(styles.root, styles[size], {
      [styles[theme]]: theme !== themes.default,
      [styles[color]]: color !== 'default',
      [className]: className,
      [styles.isSkeleton]: isSkeleton,
    });

    return (
      <div className={rootClasses} style={style} title={title} {...extra}>
        {isSkeleton ? <SkeletonBar extraClasses={styles.hintSkeletonStyles} /> : children}
      </div>
    );
  }
}

HintMessage.defaultProps = {
  color: 'default',
};

HintMessage.contextType = ThemeContext;

export const formHintMessagePropTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Цвет текста
   */
  color: PropTypes.string,
  /**
   * Размер
   */
  size: LMS,
  /**
   * skeleton-поведение
   */
  isSkeleton: PropTypes.bool,
  /**
   * Title для текста
   */
  title: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

HintMessage.propTypes = formHintMessagePropTypes;

export default HintMessage;
