import React from 'react';

/**
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export type Callback = (...args: any[]) => unknown;
export const useEvent = <T extends Callback = Callback>(callback: T): T => {
  const callbackRef = React.useRef<T>(callback);
  callbackRef.current = callback;

  const fn = ((...args) => callbackRef.current(...args)) as T;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback<T>(fn, []);
};
