import { MouseEvent as ReactMouseEvent } from 'react';

export type Callback = (event: ReactMouseEvent) => void;

class ClickDetector {
  clickedElement: Node | null = null;
  composedPath: Node[] = [];
  clickCallbackCollection = new Set<Callback>();

  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      document.addEventListener('click', this.clickListener);
    });
  }

  removeClickListener = () => {
    document.removeEventListener('click', this.clickListener);
  };

  clickListener = (event: MouseEvent) => {
    this.composedPath = event.composedPath() as Node[];

    this.clickedElement = event.target as Node;
    /**
     * изначально в интерфейсах DropDown шла завязка React.MouseEvent,
     * а в addEventListener и removeEventListener нам нужен DOM MouseEvent
     * тут происходит конвертация типа MouseEvent в React.MouseEvent
     * @todo:
     * подумать как сделать более человечно
     */
    this.clickCallbackCollection.forEach(cb => cb(event as unknown as ReactMouseEvent));
  };

  getLastClickedElement = () => {
    return this.clickedElement;
  };

  isElemContainClick = (domElement: Node | null) => {
    if (!domElement) {
      return false;
    }

    return (
      domElement.contains(this.clickedElement) ||
      this.composedPath.some(item => {
        if (item.nodeType === Node.ELEMENT_NODE) {
          return domElement.contains(item as Node);
        }
        return false;
      })
    );
  };

  isClickContainElem = (domElement: Node | null) => {
    return domElement && (this.clickedElement as Node).contains(domElement);
  };

  addClickCallback = (callback: Callback) => {
    this.clickCallbackCollection.add(callback);
  };

  removeClickCallback = (callback: Callback) => {
    this.clickCallbackCollection.delete(callback);
  };
}

const clickDetectorInstance = new ClickDetector();

export default clickDetectorInstance;
