import React from 'react';
import classNames from 'classnames';
import { zIndexService, clickDetector, useEvent } from '@sc-reactkit/internal';
import IExtra from '@sc-reactkit/internal/interfaces/extra';

import styles from './dropdown.module.scss';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';
import { FloatWrapperRefType, IFloatWrapperProps } from './float-wrapper';

const { addClickCallback, removeClickCallback, isElemContainClick } = clickDetector;

export type IBaseDropDownAreaClick = {
  CONTENT_CLICK: string;
  OUTSIDE_CLICK: string;
  TOGGLE_CLICK: string;
};

const BASE_DROPDOWN_AREA_CLICKS: IBaseDropDownAreaClick = {
  CONTENT_CLICK: 'content_click',
  TOGGLE_CLICK: 'toggle_click',
  OUTSIDE_CLICK: 'outside_click',
};

export type InnerRefType = {
  buttonRef?: (item: HTMLDivElement) => void;
  contentRef?: (item: HTMLDivElement) => void;
};

export interface IBaseDropdownProps extends IExtra, IExtendClassProps {
  closeByOutsideClick?: boolean;
  closeByContentClick?: boolean;
  closeBySelfClick?: boolean;
  baseDropdownProps?: Partial<IFloatWrapperProps>;
  floatWrapperProps?: Partial<IFloatWrapperProps>;
  isControlled?: boolean;
  isOpen?: boolean;
  isDisabled?: boolean;
  className?: string;
  isMoveDetect?: boolean;
  moveDetectorSettings?: { node?: Node; time?: number; isStop?: boolean; callback?(): void };
  onToggle?(value: boolean, areaClickType: string): void;
}

/**
 * Компонент является обёрткой над компонентом FloatWrapper и управляет
 * его состоянием.
 * Компонент может работать в режиме controlled и uncontrolled.
 * Принцип их работы несколько отличается. Описание отличия представлено ниже:
 *
 * Uncontrolled:
 * Состояние видимости всплывающего контента управляется свойством `node` из стейта.
 * Если в проп node компонента FloatWrapper приходит domElement, то всплывающий
 * контент показывается. Если его надо скрыть, то в проп node компонента FloatWrapper
 * передаём undefined.
 *
 * Controlled
 * В данном случае проп node компонента FloatWrapper domElement мы передаём сразу
 * при его определении и не меняем. Управление видимости осуществляется транзитом
 * пропа isOpen в проп isOpen компонента FloatWrapper.
 *
 * За включение controlled-mode отвечает проп isControlled компонента BaseDropdown
 */

const BaseDropdown: React.FC<IBaseDropdownProps> = props => {
  const {
    closeByOutsideClick = true,
    closeBySelfClick = true,
    closeByContentClick = true,
    isMoveDetect = true,
    onToggle,
    style,
    baseDropdownProps,
    floatWrapperProps,
    isControlled,
    isOpen,
    className,
    moveDetectorSettings,
    isDisabled,
    children,
    extra,
  } = props;

  if (baseDropdownProps) {
    // eslint-disable-next-line no-console
    console.warn('BaseDropdown message: prop "baseDropdownProps" is deprecated. Use "floatWrapperProps" prop');
  }

  const [node, setNode] = React.useState<HTMLInputElement | undefined>(undefined);
  const buttonRef = React.useRef<HTMLInputElement | null>(null);
  const contentRef = React.useRef<HTMLInputElement | null>(null);
  const dropdownRef = React.useRef<HTMLInputElement | null>(null);
  const floatWrapperRef = React.useRef<HTMLDivElement | null>(null);

  const removeComponent = React.useCallback(() => {
    if (floatWrapperRef.current) {
      zIndexService.removeToComponentStore(floatWrapperRef.current);
    }

    /**
     * В режиме isControlled dom-элемент в портале не удаляется, а просто скрывается
     */
    if (isControlled && floatWrapperRef.current) {
      floatWrapperRef.current.style.zIndex = '';
    }
  }, [isControlled]);

  /**
   * Если controlled, то вызываем только коллбек с актуальным состоянием
   * всплывающего контента
   * @param clickArea
   */

  const showPopup = React.useCallback(
    (clickArea: string) => {
      if (!isControlled) {
        setNode(dropdownRef.current || undefined);
      }

      if (floatWrapperRef.current) {
        floatWrapperRef.current.style.zIndex = zIndexService.addToComponentStore(floatWrapperRef.current);
      }

      onToggle?.(true, clickArea);
    },
    [isControlled, onToggle],
  );

  const closePopup = React.useCallback(
    (clickArea: string) => {
      if (!isControlled) {
        setNode(undefined);
      }

      removeComponent();

      onToggle?.(false, clickArea);
    },
    [isControlled, onToggle, removeComponent],
  );

  const checkClick = useEvent(() => {
    const isButtonClick = isElemContainClick(buttonRef.current);
    const isContentClick = isElemContainClick(contentRef.current);
    const isOutsideClick = !isButtonClick && !isContentClick;
    // если компонент controlled, то за видимость плавающего контента отвечает
    // проп isOpen, иначе за это отвечает состояние node.
    const flag = isControlled ? isOpen : node;
    // Необходимо при изменении пропса isDisabled компонента TDropdown
    // Если TDropdown становится disabled и при этом дропдаун открыт, то далее BaseDropdown отреагирует на клик и
    // закроется. В противном случает, если TDropdown disabled и выпадающий контент закрыт - ничего не произойдёт.
    if (isDisabled && !flag) {
      return;
    }
    // скрытый контент + клик по кнопке
    if (!flag && isButtonClick) {
      showPopup(BASE_DROPDOWN_AREA_CLICKS.TOGGLE_CLICK);
      return;
    }
    // видимый контент + клик по кнопке
    if (closeBySelfClick && flag && isButtonClick) {
      closePopup(BASE_DROPDOWN_AREA_CLICKS.TOGGLE_CLICK);
      return;
    }
    // видимый контент + клик по контенту
    if (closeByContentClick && flag && isContentClick) {
      closePopup(BASE_DROPDOWN_AREA_CLICKS.CONTENT_CLICK);
      return;
    }
    // видимый контент + клик вне кнопки и контента
    if (closeByOutsideClick && flag && isOutsideClick) {
      closePopup(BASE_DROPDOWN_AREA_CLICKS.OUTSIDE_CLICK);
    }
  });

  React.useEffect(() => {
    addClickCallback(checkClick);
    return () => {
      removeClickCallback(checkClick);
      removeComponent();
    };
  }, [checkClick, removeComponent]);

  const createDropdownRef = (elementNode: HTMLInputElement) => {
    if (!elementNode) {
      return;
    }
    dropdownRef.current = elementNode;
    if (isControlled) {
      setNode(dropdownRef?.current);
    }
  };

  const createButtonRef = (elementNode: HTMLInputElement) => {
    if (elementNode) {
      buttonRef.current = elementNode;
    }
  };

  const createContentRef = (elementNode: HTMLInputElement) => {
    if (elementNode) {
      contentRef.current = elementNode;
    }
  };

  /**
   * За всплытие и порталирование контента отвечает класс FloatWrapper. Но об окружении этот класс ничего не знает и
   * вычислять zIndex относительно других модальных элементов ему не надо, это не его залача. Однако BaseDropdown
   * (компоненту-обёртке над FloatWrapper) необходим ref-объект всплывающего контента для присваивания ему вычисляемого
   * zIndex. Поэтому этот метод передаётся во FloatWrapper и в аргументах получает нужный dom-элемент
   */
  const setFloatWrapperRef: FloatWrapperRefType = ref => {
    floatWrapperRef.current = ref;
  };

  const newChildren = React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    return React.cloneElement(child, {
      innerRef: { buttonRef: createButtonRef, contentRef: createContentRef },
      floatWrapperRef: setFloatWrapperRef,
      baseDropdownProps: { ...child.props.baseDropdownProps, ...baseDropdownProps },
      floatWrapperProps: { ...child.props.floatWrapperProps, ...floatWrapperProps },
      node,
      isControlled,
      isOpen,
      isMoveDetect,
      moveDetectorSettings,
      key: index.toString(),
    });
  });

  const rootClasses = classNames(styles.dropdown, className);
  return (
    <div className={rootClasses} ref={createDropdownRef} style={style} {...extra}>
      {newChildren}
    </div>
  );
};

export { BASE_DROPDOWN_AREA_CLICKS };
export default BaseDropdown;
