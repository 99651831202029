import React from 'react';
import classNames from 'classnames';
import FloatWrapper, { FloatWrapperRefType } from './float-wrapper';

import styles from './dropdown.module.scss';
import ISpread from '@sc-reactkit/internal/interfaces/spread';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import { InnerRefType } from './base-dropdown';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

export interface IDropdownContentProps extends ISpread, IExtra, Pick<IExtendClassProps, 'className'> {
  baseDropdownProps?: object;
  floatWrapperProps?: object;
  isOpen?: boolean;
  innerRef?: InnerRefType;
  floatWrapperRef?: FloatWrapperRefType;
}

const DropdownContent: React.FC<IDropdownContentProps> = props => {
  const {
    innerRef,
    className,
    children,
    isOpen,
    floatWrapperRef,
    baseDropdownProps,
    floatWrapperProps,
    extra,
    ...other
  } = props;
  return (
    <FloatWrapper
      isOpen={isOpen}
      floatWrapperRef={floatWrapperRef}
      {...baseDropdownProps}
      {...floatWrapperProps}
      {...other}
    >
      <div className={classNames(styles.dropdownContent, className)} ref={innerRef?.contentRef} {...extra}>
        {children}
      </div>
    </FloatWrapper>
  );
};

export default DropdownContent;
