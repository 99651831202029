import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './menu-container.module.scss';

export default class PageContainer extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    extra: PropTypes.object,
  };

  render() {
    const { children, extra } = this.props;
    return (
      <div className={styles.page} {...extra}>
        {children}
      </div>
    );
  }
}
