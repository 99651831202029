import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './horizontal-indicator.module.scss';

const colors = ['orange', 'red', 'blue'];

export default class HorizontalIndicator extends React.Component {
  static propTypes = {
    color: PropTypes.oneOf([PropTypes.string, ...colors]),
    /**
     * Кастомный инлайн-стиль
     */
    style: PropTypes.objectOf(PropTypes.any),
    /**
     * Кастомное название стиля
     */
    className: PropTypes.string,
    /**
     * Произвольные атрибуты
     */
    extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    const { className, color, style: propStyle = {}, extra } = this.props;
    const isPresetColor = colors.includes(color);
    const style = isPresetColor ? propStyle : { background: color, ...propStyle };

    const rootClasses = classNames(styles.root, {
      [styles[color]]: isPresetColor,
      [className]: className,
    });

    return <div className={rootClasses} style={style} {...extra} />;
  }
}
