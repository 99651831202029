import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';

import styles from './page.module.scss';

/**
 * Контентная часть страницы
 */
class Page extends React.Component {
  render() {
    const { children, className, style, extra } = this.props;
    const { theme } = this.context;
    const rootStyles = classNames(styles.root, {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    return (
      <div className={rootStyles} style={style} {...extra}>
        {children}
      </div>
    );
  }
}

Page.contextType = ThemeContext;

Page.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Page;
