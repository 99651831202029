/* eslint-disable import/prefer-default-export */
export function getButtonProps(isButton, extra) {
  return isButton
    ? {
        role: 'button',
        tabIndex: 0,
        ...extra,
      }
    : {};
}
