import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getButtonProps } from './utils';

import styles from './list.module.scss';

/**
 * Текст
 */
class ListItemText extends React.Component {
  static IS_TEXT = true;

  onClick = event => {
    const { onClick, afterClick } = this.props;
    onClick(event);
    if (afterClick) {
      afterClick();
    }
  };

  render() {
    const {
      primary,
      secondary,
      onClick,
      disabled,
      className,
      style,
      isTextEllipsis,
      extra,
      afterClick,
      hasBolderText,
      ...other
    } = this.props;
    const isButton = typeof onClick === 'function';
    const buttonProps = getButtonProps(isButton && !disabled, { onClick: this.onClick });

    const textClasses = classNames(styles.text, {
      [styles.disabledText]: disabled,
      [styles.hasSecondary]: secondary,
      [styles.ellipsis]: isTextEllipsis,
      [styles.hasBolderText]: hasBolderText,
      [className]: className,
    });

    return (
      <div className={textClasses} style={style} {...buttonProps} {...extra} {...other}>
        {isButton && <div className={styles.back} />}
        <p className={styles.primary}>{primary}</p>
        {secondary && <p className={styles.secondary}>{secondary}</p>}
      </div>
    );
  }
}

ListItemText.defaultProps = {
  disabled: false,
  isTextEllipsis: true,
};

ListItemText.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Текст
   */
  primary: PropTypes.string.isRequired,
  /**
   * Допонительный текст
   */
  secondary: PropTypes.string,
  /**
   * Должен ли обрезаться текст
   */
  isTextEllipsis: PropTypes.bool,
  /**
   * Обработчик клика
   */
  onClick: PropTypes.func,
  /**
   * Действие после клика
   */
  afterClick: PropTypes.func,
  /**
   * Элемент недоступен
   */
  disabled: PropTypes.bool,
  /**
   * Bolder вес для текста
   */
  hasBolderText: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ListItemText;
