import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import Icon from '@sc-reactkit/icon';
import { LMS } from '@sc-reactkit/internal';

import { ArrowForward } from './assests/svg-items.js';

import styles from './menu-item.module.scss';

export default class MenuItem extends React.Component {
  render() {
    const {
      icon,
      label,
      onClick,
      isActive,
      disabled,
      hasChildren,
      className,
      style,
      size,
      isCollapsed,
      customStatus,
      statusOffset,
      iconsPack,
      extra,
    } = this.props;
    const { theme, size: contextSize } = this.context;

    const rootClasses = classNames(styles.root, styles[size || contextSize], {
      [styles.active]: isActive,
      [styles.disable]: disabled,
      [styles.parent]: hasChildren,
      [styles.collapsed]: isCollapsed,
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    const labelStyles = classNames(styles.label, {
      [styles.iconless]: !icon,
    });

    return (
      <div
        className={rootClasses}
        role="button"
        onClick={disabled ? undefined : onClick}
        onKeyPress={disabled ? undefined : onClick}
        tabIndex={0}
        title={label}
        style={style}
        {...extra}
      >
        {icon && (
          <div className={styles.icon}>
            <Icon
              source="icons24"
              name={icon}
              iconsPack={iconsPack}
              disabled={disabled}
              customStatus={customStatus}
              statusOffset={statusOffset}
            />
          </div>
        )}
        {hasChildren && (
          <>
            <div className={styles.forward}>
              <ArrowForward disabled={disabled} theme={theme} />
            </div>
            {isCollapsed && (
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" className={styles.parentIcon}>
                <path d="M8 0v8H0Z" className={styles.parentIconPath} />
              </svg>
            )}
          </>
        )}
        {isCollapsed && <div className={styles.letter}>{label[0].toUpperCase()}</div>}
        {!isCollapsed && <span className={labelStyles}>{label}</span>}
      </div>
    );
  }
}

MenuItem.contextType = ThemeContext;

MenuItem.defaultProps = {
  isActive: false,
  isCollapsed: false,
  disabled: false,
};

MenuItem.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Является ли текущий элемент активным выбранным
   */
  isActive: PropTypes.bool,
  /**
   * Компактный вид меню
   */
  isCollapsed: PropTypes.bool,
  /**
   * Является ли текущий элемент отключённым
   */
  disabled: PropTypes.bool,
  /**
   * Есть ли потомки у меню
   */
  hasChildren: PropTypes.bool,
  /**
   * Заголовок элемента меню
   */
  label: PropTypes.string.isRequired,
  /**
   * Иконка элемента меню
   */
  icon: PropTypes.string,
  /**
   * Пак иконок
   */
  iconsPack: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Обрабочтик клика по меню. Опционально
   */
  onClick: PropTypes.func,
  /**
   * Адрес пункта меню
   */
  link: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  /**
   * Размер
   */
  size: LMS,
  /**
   * Кастомный статус
   */
  customStatus: PropTypes.element,
  /**
   * Смещение статуса/кастомного статуса
   */
  statusOffset: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
