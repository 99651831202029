import React from 'react';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import styles from './content-block.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

export interface IContentBlockProps extends IExtra, IExtendClassProps {
  /**
   * flex-поведение элемента
   */
  isFlex?: boolean;
}

const ContentBlock: React.FC<IContentBlockProps> = props => {
  const { isFlex, children, className, style, extra } = props;
  const { theme = themes.default } = React.useContext(ThemeContext);

  const classes = classNames(styles.root, {
    [styles.dark]: theme === themes.dark,
    [styles.isFlex]: isFlex,
    [String(className)]: className,
  });

  return (
    <div className={classes} style={style} {...extra}>
      {children}
    </div>
  );
};

export default ContentBlock;
