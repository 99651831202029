import React from 'react';
import PropTypes from 'prop-types';

// TODO: всё это выпилится, как только появятся соответствующие иконки
const colors = new Map([
  [
    'default',
    new Map([
      [false, 'black'],
      [undefined, 'black'],
      [true, 'black'],
    ]),
  ],
  [
    'dark',
    new Map([
      [false, 'white'],
      [undefined, 'white'],
      [true, 'white'],
    ]),
  ],
]);

export const Hamburger = ({ theme = 'default', disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill={colors.get(theme).get(disabled)} d="M2 18h20v2H2z" />
    <path fill={colors.get(theme).get(disabled)} d="M2 11h20v2H2z" />
    <path fill={colors.get(theme).get(disabled)} d="M2 4h20v2H2z" />
  </svg>
);

Hamburger.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};

export const ArrowBack = ({ theme = 'default', disabled }) => (
  <svg width="32" height="32">
    <path
      fill={colors.get(theme).get(disabled)}
      transform="translate(19, 23) rotate(-180)"
      d="M9 7.5L1.105 0 0 1.05 6.789 7.5 0 13.95 1.105 15z"
    />
  </svg>
);

ArrowBack.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};

export const ArrowForward = ({ theme = 'default', disabled }) => (
  <svg width="32" height="32">
    <g>
      <path fill="none" d="M8 8h16v16H8z" />
      <path fill="none" d="M8 8h16v16H8z" />
      <path
        d="m13.707 22.79-1.414-1.414 5.293-5.293-5.293-5.293 1.414-1.414 6.707 6.707Z"
        fill={colors.get(theme).get(disabled)}
      />
    </g>
  </svg>
);

ArrowForward.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};
