import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import TButton from './button';

import styles from './segment-bar.module.scss';

class SegmentBar extends Component {
  renderChildren = () => {
    const { children, isVertical, isTruncated } = this.props;

    const tButtonClasses = isVertical || isTruncated ? 'segment-vertical-button' : 'segment-button';

    return children.map(child => {
      if (child.type === TButton) {
        return React.cloneElement(child, { className: tButtonClasses });
      }
      return undefined;
    });
  };

  render() {
    const { isVertical, isTruncated, isHidden, extra } = this.props;
    const { theme } = this.context;
    const rootClasses = classNames(styles.root, {
      [styles.isVertical]: isVertical || isTruncated,
      [styles[theme]]: theme !== themes.default,
      [styles.isHidden]: isHidden,
    });

    return (
      <div className={rootClasses} {...extra}>
        <div className={styles.buttons}>{this.renderChildren()}</div>
      </div>
    );
  }
}

SegmentBar.contextType = ThemeContext;

SegmentBar.defaultProps = {};

SegmentBar.propTypes = {
  /**
   * Вертикальное расположение элементов в сегмент-баре
   */
  isVertical: PropTypes.bool,
  /**
   * Флаг, обозначающий, что компонент находится в выпадающем списке тулбара
   */
  isTruncated: PropTypes.bool,
  /**
   * Скрытие компонента. Необходимо для выпадающего списка в тулбаре
   */
  isHidden: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default SegmentBar;
