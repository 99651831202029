import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import styles from './list.module.scss';
import ListItem from './list-item';
import ListItemSection from './list-item-section';

/**
 * Список
 */
class SkeletonList extends React.Component {
  render() {
    const { border, className } = this.props;
    const { theme, size } = this.context;
    const listClasses = classNames(styles.root, styles[size], {
      [styles.border]: border,
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });
    return (
      <div className={listClasses}>
        <ListItemSection isSkeleton />
        <ListItem isSkeleton />
        <ListItem isSkeleton />
        <ListItem isSkeleton />
        <ListItem isSkeleton />
        <ListItem isSkeleton />
      </div>
    );
  }
}

SkeletonList.contextType = ThemeContext;

SkeletonList.defaultProps = {
  border: true,
};

SkeletonList.propTypes = {
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Показывать ли рамку вокруг списка
   */
  border: PropTypes.bool,
};

export default SkeletonList;
