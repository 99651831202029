import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import SkeletonList from './skeleton-list';
import styles from './list.module.scss';

/**
 * Список
 */
class List extends React.Component {
  render() {
    const { border, afterClick, children, isSkeleton, className, style, extra } = this.props;
    const { theme, size } = this.context;
    const listClasses = classNames(styles.root, styles[size], {
      [styles.border]: border,
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });
    const childrenProps = {
      ...(afterClick ? { afterClick } : {}),
    };
    return isSkeleton ? (
      <SkeletonList />
    ) : (
      <div className={listClasses} style={style} {...extra}>
        {React.Children.map(children, child => (child ? React.cloneElement(child, childrenProps) : null))}
      </div>
    );
  }
}

List.contextType = ThemeContext;

List.defaultProps = {
  border: true,
};

List.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Показывать ли рамку вокруг списка
   */
  border: PropTypes.bool,
  /**
   * skeleton-поведение
   */
  isSkeleton: PropTypes.bool,
  /**
   * Действие после клика
   */
  afterClick: PropTypes.func,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default List;
