import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';

import styles from './preloader.module.scss';

class Preloader extends React.Component {
  render() {
    const { size, className, style, extra } = this.props;
    const { theme } = this.context;
    const preloaderClasses = classNames(styles.root, styles[size], {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });
    return <div className={preloaderClasses} style={style} {...extra} />;
  }
}

Preloader.contextType = ThemeContext;

Preloader.defaultProps = {
  size: 'default',
};

Preloader.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Размер лоадера
   */
  size: PropTypes.oneOf(['default', 'large', 'medium', 'small']),
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Preloader;
