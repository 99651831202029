import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getButtonProps } from './utils';

import styles from './list.module.scss';

/**
 * Произвольный контейнер
 */
class ListItemContainer extends React.Component {
  static IS_CONTAINER = true;

  onClick = event => {
    const { onClick, afterClick } = this.props;
    onClick(event);
    if (afterClick) {
      afterClick();
    }
  };

  render() {
    const { alignToTop, onClick, isEdit, children, disabled, afterClick, passAfterClick, className, style, extra } =
      this.props;
    const isButton = typeof onClick === 'function';
    const buttonProps = getButtonProps(isButton && !disabled, { onClick: this.onClick });
    const childIsComponent = children && typeof children.type !== 'string';
    const childrenProps = childIsComponent ? { ...(passAfterClick ? { afterClick } : {}) } : {};
    // disabled будет прокидываться дочерним компонентам только в том случае,
    // если будет true. Иначе он перезаписывает disabled дочерних компонентов в
    // любом случае
    // http://gitlab.design.securitycode.ru/front/sc-reactkit/-/issues/415
    if (childIsComponent && disabled) {
      childrenProps.disabled = true;
    }

    const containerClasses = classNames(styles.container, {
      [styles.button]: isButton,
      [styles.alignToTop]: alignToTop,
      [className]: className,
    });
    const editButton = classNames({ [styles.isEdit]: isEdit });
    return (
      <div className={containerClasses} style={style} {...buttonProps} {...extra}>
        <div className={editButton}>
          {React.Children.map(children, child => React.cloneElement(child, { ...childrenProps }))}
        </div>
      </div>
    );
  }
}

ListItemContainer.defaultProps = {
  disabled: false,
  passAfterClick: false,
};

ListItemContainer.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Обработчик клика по кнопке
   */
  onClick: PropTypes.func,
  /**
   * Действие после клика
   */
  afterClick: PropTypes.func,
  /**
   * Передавать afterClick дочерним элементам
   */
  passAfterClick: PropTypes.bool,
  /**
   * Выровнять элемент по верхнему краю с отступом 8px
   */
  alignToTop: PropTypes.bool,
  /**
   * Элемент недоступен
   */
  disabled: PropTypes.bool,
  /**
   * Серый фон для кнопки, если она edit
   */
  isEdit: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ListItemContainer;
