export const ANCHORS = {
  // common
  CENTER: 'center',
  // vertical
  TOP: 'top',
  BOTTOM: 'bottom',
  // horizontal
  LEFT: 'left',
  RIGHT: 'right',
};

export const checkVertical = (originValue: number, height: number, vertical: string, isModal: boolean) => {
  const sign = isModal ? -1 : 1;

  switch (vertical) {
    case ANCHORS.TOP:
      return originValue;
    case ANCHORS.CENTER:
      return originValue + Math.round(height / 2) * sign;
    case ANCHORS.BOTTOM:
      return originValue + Math.round(height) * sign;
    default:
      return originValue;
  }
};

export const checkHorizontal = (originValue: number, width: number, horizontal: string, isModal: boolean) => {
  const sign = isModal ? -1 : 1;

  switch (horizontal) {
    case ANCHORS.LEFT:
      return originValue;
    case ANCHORS.CENTER:
      return originValue + Math.round(width / 2) * sign;
    case ANCHORS.RIGHT:
      return originValue + Math.round(width) * sign;
    default:
      return originValue;
  }
};

type BorderParamsPositionType = { top: number; left: number };
interface ICheckBordersParams {
  position?: BorderParamsPositionType;
  /**
   * при использовании внутри DropdownSelect берутся размеры рутового элемента. Во всех остальных случаях
   * это размеры всплывающего контента
   */
  sizes: { height: number; width: number };
  padding: number;
  offset?: { x: number; y: number };
}

/**
 * Метод проверяет, помещается ли всплывающее окно в window. Если не помещается, то координаты сдвигаются так, чтобы был
 * отступ между краем экрана и всплываюшим контентом.
 */
export const checkBorders = (params: ICheckBordersParams) => {
  const { position, offset, padding, sizes } = params;
  const newPosition = Object.assign({}, position);

  const { innerWidth, innerHeight } = window;

  if (newPosition.top < padding) {
    newPosition.top = padding;
  }

  if (newPosition.top + sizes.height > innerHeight) {
    newPosition.top = innerHeight - (sizes.height + padding);
  }

  if (newPosition.left < padding) {
    newPosition.left = padding;
  }

  if (newPosition.left + sizes.width > innerWidth) {
    newPosition.left = innerWidth - (sizes.width + padding);
  }

  if (offset) {
    newPosition.top += offset.y;
    newPosition.left += offset.x;
  }

  return newPosition;
};

type PositionType = {
  top: number;
  left: number;
};

interface ICheckPositionPriorityParams {
  position: PositionType;
  sizes: { width: number; height: number };
  padding: any;
}
export const checkPositionPriority = (params: ICheckPositionPriorityParams) => {
  const { position, sizes, padding } = params;
  const { innerWidth, innerHeight } = window;

  return {
    right: position.left + sizes.width < innerWidth,
    top: position.top > padding,
    left: position.left > padding,
    bottom: position.top + sizes.height < innerHeight,
  };
};

const checkBordersUtils = {
  ANCHORS,
  checkVertical,
  checkHorizontal,
  checkBorders,
  checkPositionPriority,
};

export default checkBordersUtils;
