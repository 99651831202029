import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './menu-container.module.scss';

export default class MenuContainer extends Component {
  static propTypes = {
    isCollapsed: PropTypes.bool,
    isAnimated: PropTypes.bool,
    width: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    extra: PropTypes.object,
  };

  static defaultProps = {
    isCollapsed: false,
    isAnimated: true,
    width: 350,
  };

  renderChildren = child => {
    const { isCollapsed } = this.props;
    return React.cloneElement(child, { isCollapsed });
  };

  render() {
    const { children, isCollapsed, isAnimated, width, extra } = this.props;
    const menuClasses = classNames(styles.menu, {
      [styles.animated]: isAnimated,
    });
    const menuStyles = { width: isCollapsed ? '3rem' : width };
    return (
      <div className={menuClasses} style={menuStyles} {...extra}>
        {React.Children.map(children, this.renderChildren)}
      </div>
    );
  }
}
