import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FallbackUi from '@sc-reactkit/fallback-ui';
import { ThemeContext, themes } from 'sc-context';

import styles from './layout.module.scss';

/**
 * Базовый фрейм страницы
 */
class Layout extends React.Component {
  state = { hasError: false };

  componentDidCatch = (error, info) =>
    this.setState({ hasError: true }, () => this.props.onCatch && this.props.onCatch(error, info));

  render() {
    if (this.state.hasError) {
      return <FallbackUi>{this.props.fallbackUi}</FallbackUi>;
    }

    const { children, className, style, extra } = this.props;
    const { theme } = this.context;
    const rootClasses = classNames(styles.root, {
      [styles.dark]: theme === themes.dark,
      [className]: className,
    });

    return (
      <div className={rootClasses} style={style} {...extra}>
        {children}
      </div>
    );
  }
}

Layout.contextType = ThemeContext;

Layout.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Колбэк, вызываемый при перехвате ошибки
   */
  onCatch: PropTypes.func,
  /**
   * Элемент-заглушка, для отрисовки в случае падения компонента
   */
  fallbackUi: PropTypes.element,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Layout;
