import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@sc-reactkit/icon';
import { ThemeContext, themes } from 'sc-context';

import styles from './indicator.module.scss';

class Indicator extends React.Component {
  render() {
    const { onClick, status, count, className, style, extra } = this.props;
    const { theme } = this.context;
    const indicatorClasses = classNames(styles.root, {
      [styles.clickable]: onClick,
      [styles[status]]: status,
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });
    const label = count > 9999 ? '9999+' : count;
    let iconName;
    switch (status) {
      case 'warning':
        iconName = 'status_information';
        break;
      case 'error':
        iconName = 'additional-objects_event';
        break;
      default:
        iconName = 'base-actions_agree';
        break;
    }
    return (
      <div
        className={indicatorClasses}
        style={style}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
        {...extra}
      >
        {count ? <span className={styles.count}>{label}</span> : <Icon name={iconName} color="white" size={22} />}
      </div>
    );
  }
}

Indicator.contextType = ThemeContext;

Indicator.defaultProps = {
  status: 'normal',
};

Indicator.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Обработчик клика по индикатору
   */
  onClick: PropTypes.func,
  /**
   * Статус
   */
  status: PropTypes.oneOf(['normal', 'warning', 'error']),
  /**
   * Количество проблем
   */
  count: PropTypes.number,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Indicator;
