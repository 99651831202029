import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { SkeletonBar } from '@sc-reactkit/helpers';
import styles from './list.module.scss';

/**
 * Заголовок
 */
class ListItemSection extends React.Component {
  static IS_SECTION = true;

  render() {
    /**
     * вытаскиваем afterClick, чтобы не кидать его дальше, т.к.
     * afterClick будет передан сюда через компонент List
     */
    // eslint-disable-next-line react/prop-types
    const { children, className, style, isSkeleton, selected, disabled, extra } = this.props;
    const titleClasses = classNames(styles.title, {
      [className]: className,
      [styles.skeletonTitle]: isSkeleton,
      [styles.selected]: selected,
      [styles.disabled]: disabled,
    });

    return (
      <p className={titleClasses} style={style} {...extra}>
        {selected && <div className={styles.selectedBar} />}
        {isSkeleton ? <SkeletonBar extraClasses={styles.skeletonSection} /> : children}
      </p>
    );
  }
}

ListItemSection.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Элемент недоступен
   */
  disabled: PropTypes.bool,
  /**
   * Элемент выбран
   */
  selected: PropTypes.bool,
  /**
   * skeleton-поведение
   */
  isSkeleton: PropTypes.bool,
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ListItemSection;
