import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './fallback-ui.module.scss';

const FallbackUi = ({ children, className, style, extra }) => {
  const rootClasses = classNames(styles.root, {
    [className]: className,
  });
  return (
    <div className={rootClasses} style={style} {...extra}>
      {children}
    </div>
  );
};

FallbackUi.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default FallbackUi;
