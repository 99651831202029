import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './spacer.module.scss';

class Spacer extends React.Component {
  getStyle = size => {
    if (!size) {
      return {};
    }
    const width = `${size / 16}rem`;
    return { width, flexBasis: width };
  };

  render() {
    const { extra, size } = this.props;
    const rootClasses = classNames(styles.root, {
      [styles.size]: size,
    });
    return <div className={rootClasses} data-width="0" style={this.getStyle(size)} {...extra} />;
  }
}

Spacer.propTypes = {
  /**
   * Размер
   */
  size: PropTypes.number,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Spacer;
