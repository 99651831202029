import React from 'react';
import classNames from 'classnames';
import styles from './dropdown.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import { InnerRefType } from './base-dropdown';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

export interface IDropdownToggleProps extends IExtra, IExtendClassProps {
  innerRef?: InnerRefType;
}

const DropdownToggle: React.FC<IDropdownToggleProps> = props => {
  const { innerRef, className, children, extra } = props;

  return (
    <div className={classNames(styles.dropdownToggle, className)} ref={innerRef?.buttonRef} {...extra}>
      {children}
    </div>
  );
};

export default DropdownToggle;
