import React from 'react';
import PropTypes from 'prop-types';

export default Component => {
  return class extends React.Component {
    static propTypes = {
      /**
       * Опциональное закрытие модальных компонентов по Esc
       */
      isCloseByEsc: PropTypes.bool,
    };

    componentDidMount() {
      document.addEventListener('keydown', this.onKeyPressListener, false);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.onKeyPressListener, false);
    }

    createRef = ref => {
      if (ref) {
        this.ref = ref;
      }
    };

    onKeyPressListener = event => {
      const { isCloseByEsc = true } = this.props;

      if (!isCloseByEsc) {
        return;
      }

      if (event.which === 27) {
        if (this.ref && this.ref.onCloseModalComponent) {
          this.ref.onCloseModalComponent();
        }
      }
    };

    render() {
      const { ...props } = this.props;
      return <Component ref={this.createRef} {...props} />;
    }
  };
};
