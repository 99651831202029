import React from 'react';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';

import styles from './button.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import { IButtonProps } from './button';

export interface IDialogBarProps extends IExtra {
  /**
   * Кнопки растянуты по всей ширине
   */
  isWide?: boolean;
}

const DialogBar: React.FC<IDialogBarProps> = props => {
  const { isWide, children, extra } = props;
  const { theme = themes.default } = React.useContext(ThemeContext);

  const rootClasses = classNames(styles.dialogBar, {
    [styles[theme]]: theme !== themes.default,
  });

  const buttonClasses = classNames(styles.dialogButton, { [styles.isWide]: isWide });

  return (
    <div className={rootClasses} {...extra}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return (
            <div className={buttonClasses}>
              {React.cloneElement(child as React.ReactElement<IButtonProps>, { wide: isWide })}
            </div>
          );
        }
        return undefined;
      })}
    </div>
  );
};

export default DialogBar;
