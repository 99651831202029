import React from 'react';
import PropTypes from 'prop-types';
import styles from './list.module.scss';

/**
 * Заголовок
 */
class ListItemDivider extends React.Component {
  render() {
    const { extra } = this.props;
    return <div className={styles.line} {...extra} />;
  }
}

ListItemDivider.propTypes = {
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ListItemDivider;
