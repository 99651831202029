import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';

import Preloader from './preloader';

import styles from './preloader.module.scss';

class Container extends React.Component {
  render() {
    const { isLoading, size, children, className, style, isStretch, extra } = this.props;
    const { theme } = this.context;

    const containerClasses = classNames(styles.container, {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
      [styles.stretch]: isStretch,
    });

    return (
      <div className={containerClasses} style={style} {...extra}>
        {children}
        {isLoading && (
          <div className={styles.loading}>
            <Preloader size={size} />
          </div>
        )}
      </div>
    );
  }
}

Container.contextType = ThemeContext;

Container.defaultProps = {
  size: 'default',
  isLoading: false,
};

Container.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Отображать ли лоадер
   */
  isLoading: PropTypes.bool,
  /**
   * Добавление стиля height: 100%
   */
  isStretch: PropTypes.bool,
  /**
   * Размер лоадера
   */
  size: PropTypes.oneOf(['default', 'large', 'medium', 'small']),
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Container;
