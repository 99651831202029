import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';

import ErrorMessage from './error-message';

class ErrorMessages extends React.Component {
  render() {
    const { errors, className, style, extra, ...other } = this.props;

    return Array.isArray(errors) ? (
      errors.map(item => (
        <ErrorMessage
          className={className}
          style={style}
          key={md5(`hint_message_text_${item}`)}
          extra={extra}
          {...other}
        >
          {item}
        </ErrorMessage>
      ))
    ) : (
      <ErrorMessage className={className} style={style} extra={extra} {...other}>
        {errors}
      </ErrorMessage>
    );
  }
}

ErrorMessages.defaultProps = {};

export const formErrorMessagesPropTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Текст ошибки (ошибок)
   */
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ErrorMessages.propTypes = formErrorMessagesPropTypes;

export default ErrorMessages;
