import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@sc-reactkit/button';

import styles from './hamburger.module.scss';

class Hamburger extends React.Component {
  render() {
    const { onClick, className, extra } = this.props;
    const rootClasses = classNames(styles.root, {
      [className]: className,
    });

    return (
      <div className={rootClasses}>
        <Button
          kind="icon"
          isTransparent
          iconSource="icons24"
          icon="toolbar_menu-burger"
          onClick={onClick}
          onKeyUp={onClick}
          {...extra}
        />
      </div>
    );
  }
}

Hamburger.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Обработчик клика
   */
  onClick: PropTypes.func,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Hamburger;
