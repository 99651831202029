import React from 'react';
import classNames from 'classnames';

import * as icons from 'sc-fonts';

import { ThemeContext, themes } from 'sc-context';

import styles from './icon.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import ISpread from '@sc-reactkit/internal/interfaces/spread';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

type offsetType = number | string;
export type StatusOffsetType = { top: offsetType; right: offsetType; bottom: offsetType; left: offsetType };
export type ColorType = 'gray' | 'white' | 'red' | 'green' | 'yellow' | 'moderateGray' | 'lightGray';
export type IconSourceType = 'icons8' | 'icons16' | 'icons24';
export type IconsPackType = {
  [key: string]: string;
};

export interface IIconProps extends IExtra, ISpread, IExtendClassProps {
  /**
   * Стиль для svg-элемента
   */
  svgClassName?: string;
  /**
   * Размер иконки
   */
  size?: number;
  /**
   * Название иконки, без префикса класса
   */
  name?: string;
  /**
   * Цвет
   * 'gray', 'white', 'red', 'green', 'yellow', 'moderateGray', 'lightGray'
   * Или цвет в любом поддерживаемом веб-формате
   */
  color?: ColorType | string;
  /**
   * Иконка неактивна
   */
  disabled?: boolean;
  /**
   * Инлайн-элемент
   */
  inline?: boolean;
  /**
   * Функция-обработчик клика по иконке
   */
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  /**
   * Выбор пака иконок
   */
  source?: IconSourceType;
  /**
   * Будет ли появляться cursor: pointer при наведении
   */
  pointer?: boolean;
  /**
   * Наличие статуса у иконки
   */
  hasStatus?: boolean;
  /**
   * Цвет статуса
   */
  statusColor?: string;
  /**
   * Кастомный статус
   */
  customStatus?: React.ElementType | React.ReactElement;
  /**
   * Смещение статуса/кастомного статуса
   */
  statusOffset?: Partial<StatusOffsetType>;
  /**
   * Пак иконок
   */
  iconsPack?: IconsPackType;
}

type GetPlaceHolderParams = {
  size: number;
  fill?: string;
  className: string;
};

const getPlaceholder = ({ size, fill, className }: GetPlaceHolderParams) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    style={{ fill }}
    viewBox={`0 0 ${size} ${size}`}
    className={className}
  >
    <path d={`M0 0h${size}v1H0z`} />
    <path d={`M${size - 1} 0h1v${size}h-1z`} />
    <path d={`M0 ${size - 1}h${size}v1H0z`} />
    <path d={`M0 0h1v${size}H0z`} />
    <path d={`M1.292.423l${size - 1} ${size - 1}-.707.708L.585 1.13z`} />
    <path d={`M.646 ${size - 2}l${size - 2}-${size - 2} .707.707-${size - 2} ${size - 2}z`} />
  </svg>
);

const { icons8, icons16, icons24 } = icons;

const defaultIconPack = {
  icons8: {
    pack: icons8,
    viewBox: '0 0 8 8',
    size: 8,
  },
  icons16: {
    pack: icons16,
    viewBox: '0 0 16 16',
    size: 16,
  },
  icons24: {
    pack: icons24,
    viewBox: '0 0 24 24',
    size: 24,
  },
};

/**
 * Иконка
 */
const Icon: React.FC<IIconProps> = props => {
  const {
    name = '',
    source = 'icons16',
    statusColor = '#56bf4b',
    pointer = false,
    inline = false,
    disabled = false,
    svgClassName,
    size,
    className,
    onClick,
    iconsPack,
    customStatus,
    statusOffset,
    hasStatus,
    color: fill,
    style,
    extra,
    children,
    ...other
  } = props;

  const node = React.useRef<HTMLSpanElement>(null);
  const { theme = themes.default } = React.useContext(ThemeContext);

  const rootClasses = classNames(styles.root, {
    [styles.disabled]: disabled,
    [styles[theme]]: theme !== themes.default,
    [styles.inline]: inline,
    [String(className)]: className,
  });

  const iconClasses = classNames(styles.icon, {
    [styles.pointer]: pointer,
    [String(svgClassName)]: svgClassName,
  });

  const { viewBox, size: defaultPackSize, pack } = defaultIconPack[source];
  const currentSize = size || defaultPackSize;
  const icon = iconsPack ? iconsPack[name] : pack[name];
  const iconComponent = icon ? (
    <svg
      className={iconClasses}
      width={currentSize}
      height={currentSize}
      viewBox={viewBox}
      style={disabled ? undefined : { fill }}
      dangerouslySetInnerHTML={{ __html: icon }} // eslint-disable-line react/no-danger
      {...other}
    />
  ) : (
    getPlaceholder({ size: currentSize, fill, className: iconClasses })
  );

  const defaultButtonProps = { onClick, onKeyPress: onClick, role: 'button', tabIndex: 0 };

  const buttonProps = onClick ? defaultButtonProps : {};

  return (
    <span
      ref={node}
      className={rootClasses}
      style={Object.assign({ width: currentSize, height: currentSize }, style)}
      {...buttonProps}
      {...extra}
    >
      {hasStatus && !customStatus && (
        <span
          className={styles.status}
          style={{ backgroundColor: !disabled && statusColor ? statusColor : undefined, ...statusOffset }}
        />
      )}
      {customStatus && (
        <span className={styles.customStatus} style={{ ...statusOffset }}>
          {customStatus}
        </span>
      )}
      {iconComponent}
      {children && <span className={styles.badge}>{children}</span>}
    </span>
  );
};

export default Icon;
