type CallbackType = () => void;

export interface IMoveDetectorConstructor {
  isStop: boolean;
  time: number;
  node?: HTMLElement;
  callback?: CallbackType;
}

/**
 * Класс отслеживает изменение положения DOM-элемента.
 * Необходимость обусловлена отслеживанием и изменением положения всплывающего
 * списка при измении положения DOM-элемента, относительно которого всплывает
 * плавающий контент.
 */
class MoveDetector {
  time: number;
  isStop: boolean;
  node?: HTMLElement;
  callback?: CallbackType;
  timeStamp?: DOMHighResTimeStamp = undefined;
  x?: number;
  y?: number;

  constructor({ node, callback, time = 25, isStop = false }: Partial<IMoveDetectorConstructor>) {
    this.node = node;
    this.time = time;
    this.isStop = isStop;
    this.callback = callback;
    this.x = undefined;
    this.y = undefined;

    window.requestAnimationFrame(this.animationStep);
  }

  /**
   * DOM-элемент, изменение положения которого необходимо отслеживать
   * @param newNode
   */
  setNode = (newNode: HTMLElement) => {
    this.node = newNode;
  };

  /**
   * Коллбек, который вызывается при изменении положения
   * отслеживаемого DOM-элемента
   * @param newCallback
   */
  setCallback = (newCallback?: CallbackType) => {
    this.callback = newCallback;
  };

  /**
   * Время отслеживания положения в миллисекундах
   * @param newTime
   */
  setTime = (newTime: number) => {
    this.time = newTime;
  };

  /**
   * Функция, которая вызывается с периодом time
   * @param timestamp
   */
  animationStep = (timestamp: DOMHighResTimeStamp) => {
    if (this.isStop) {
      return;
    }

    if (!this.timeStamp) {
      this.timeStamp = timestamp;
    }

    const diff = timestamp - this.timeStamp;

    if (diff >= this.time) {
      this.checkPosition();
      this.timeStamp = timestamp;
    }

    window.requestAnimationFrame(this.animationStep);
  };

  /**
   * Метод отслеживает изменение x и y переданного DOM-элемента
   */
  checkPosition = () => {
    if (!this.node) {
      return;
    }

    const { x, y }: DOMRect = this.node.getBoundingClientRect();
    if (!this.x) {
      this.x = x;
    }
    if (!this.y) {
      this.y = y;
    }

    if (x !== this.x || y !== this.y) {
      this.y = y;
      this.x = x;
      this.callback?.();
    }
  };

  stop = () => {
    this.isStop = true;
  };

  start = () => {
    this.isStop = false;
    window.requestAnimationFrame(this.animationStep);
  };
}

export default MoveDetector;
