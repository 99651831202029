export interface IZIndexService {
  componentStore: Map<any, number>;
  addToComponentStore(component: any): number;
  removeToComponentStore(component: any): void;
  getMaxIndex(): number;
}

/**
 * Компонент-сервис для рассчета максимального zIndex модальных компонентов в определённый момент времени.
 * Необходим для решения задачи по корректному отображению модальных компонентов, монтированных через порталы.
 * Синглтон.
 *
 * Принцип работы.
 * Компонент работает по принципу хранилища. Момент рассчета корректного zIndex в разных компонентах может быть
 * вызван разными триггерами. Поэтому как только необходимо вычислить положение, компонент, использующий сервис
 * должен вызвать метод addToComponentStore, который добавит dom-элемент в стор и вернёт корректный максимальный zIndex.
 *
 * Обязательно!
 * Для корректной работы сервиса в момент скрытия модального элемента необходимо удалять dom-элемент из стора.
 *
 * Слава ссылочной целостности!
 */
class ZIndexService implements IZIndexService {
  componentStore = new Map();

  /**
   * Добавление компонента в стор
   * @param component
   * @returns {*}
   */
  addToComponentStore = (component: HTMLElement) => {
    if (this.componentStore.get(component)) {
      return undefined;
    }

    const newMaxIndex = this.getMaxIndex() + 10;

    this.componentStore.set(component, newMaxIndex);

    return newMaxIndex;
  };

  /**
   * Удаление компонента из стора
   * @param component
   */
  removeToComponentStore = (component: HTMLElement) => {
    if (this.componentStore.has(component)) {
      this.componentStore.delete(component);
    }
  };

  /**
   * Метод возвращается максимальное значение zIndex в хранилище
   * @returns {number|T}
   */
  getMaxIndex = () => {
    if (this.componentStore.size) {
      return Array.from(this.componentStore.values()).sort().reverse()[0];
    }
    return 99;
  };
}

export default new ZIndexService();
