import React from 'react';
import PropTypes from 'prop-types';
import { themes } from 'sc-context';
import styles from '../button.module.scss';

const arrowColor = new Map([
  [
    undefined,
    new Map([
      [true, '#a8a8a8'],
      [false, 'black'],
      [undefined, 'black'],
    ]),
  ],
  [
    themes.default,
    new Map([
      [true, '#a8a8a8'],
      [false, 'black'],
      [undefined, 'black'],
    ]),
  ],
  [
    themes.dark,
    new Map([
      [true, '#a8a8a8'],
      [false, 'white'],
      [undefined, 'white'],
    ]),
  ],
]);

/**
 * Стрелочка для TDropdown и TButton
 * @param theme
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
const Arrow = ({ theme, disabled }) => (
  <div className={styles.caret}>
    <svg width="16" height="16">
      <path fill="none" d="M0 0h16v16H0z" />
      <g>
        <path
          d="M8 12.061 1.47 5.53l1.06-1.06L8 9.939l5.47-5.47 1.06 1.061Z"
          fill={arrowColor.get(theme).get(disabled)}
        />
      </g>
    </svg>
  </div>
);

Arrow.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Arrow;
