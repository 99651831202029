import React from 'react';
import PropTypes from 'prop-types';

import HintMessage from './hint-message';

class ErrorMessage extends React.Component {
  render() {
    const { children, className, style, extra, ...other } = this.props;

    return (
      <HintMessage className={className} style={style} color="red" extra={extra} {...other}>
        {children}
      </HintMessage>
    );
  }
}

ErrorMessage.defaultProps = {};

export const formErrorMessagePropTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ErrorMessage.propTypes = formErrorMessagePropTypes;

export default ErrorMessage;
