import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { clickDetector, checkBordersUtils, useEvent } from '@sc-reactkit/internal';

import MoveDetector, { IMoveDetectorConstructor } from './move-detector';

import styles from './float-wrapper.module.scss';
import IExtra from '@sc-reactkit/internal/interfaces/extra';
import IExtendClassProps from '@sc-reactkit/internal/interfaces/extend-class-props';

const { checkVertical, checkHorizontal, checkBorders } = checkBordersUtils;

export type HorizontalDirectionsType = 'left' | 'center' | 'right';
export type VerticalDirectionsType = 'top' | 'center' | 'bottom';
export type FloatWrapperRefType = (ref: HTMLDivElement | null) => void;

export interface IFloatWrapperProps extends IExtra, Pick<IExtendClassProps, 'className'> {
  /**
   * node - это DOM-элемент, который опознаётся как обычный объект
   */
  node?: HTMLElement;
  /**
   * состояние show/hide плавающего контента
   */
  isOpen?: boolean;
  onClose?: React.MouseEventHandler;
  /**
   * Если isControlled установлен в true, то помимо node видимость контента будет
   * управляться свойством isOpen
   */
  isControlled?: boolean;
  /**
   * domNode - второй аргумент ReactDOM.createPortal
   */
  domNode?: Element;
  /**
   * Отступ от края, если вызывающий popup элемент выходит за границы этой области
   */
  padding?: number;
  /**
   * Вертикальное расположение точки привязки dom-элемента, по которому производится клик
   */
  nodeVertical?: VerticalDirectionsType;
  /**
   * Горизонтальное расположение точки привязки dom-элемента, по которому производится клик
   */
  nodeHorizontal?: HorizontalDirectionsType;
  /**
   * Вертикальное расположение привязки всплывающего окна
   */
  modalVertical?: VerticalDirectionsType;
  /**
   * Горизонтальное расположение привязки всплывающего окна
   */
  modalHorizontal?: HorizontalDirectionsType;
  /**
   * Добавлять overflow:hidden для родительского элемента
   */
  isOverflow?: boolean;
  /**
   * Отступ сверху для дропдауна относительно рассчитанного положения
   */
  offsetTop?: number;
  /**
   * Отступ слева для дропдауна относительно рассчитанного положения
   */
  offsetLeft?: number;
  /**
   * Необходима ли проверка на отступы от экрана. Если нет, всплывающий контент может выходить за пределы области
   * видимости
   */
  isCheckBorders?: boolean;
  /**
   * Отслеживание изменения положения DOM-элемента, относитльно которого
   * всплывает плавающий контент
   */
  isMoveDetect?: boolean;
  /**
   * Объект с настройками для MoveDetector
   */
  moveDetectorSettings?: Partial<IMoveDetectorConstructor>;
  /**
   * Выпадающий контент такой же ширины, как и кнопка
   */
  isSameWidth?: boolean;
  /**
   * Флаг, указывающий, что компонент используется внутри DropdownSelect. Внутренний пропс для DropdownSelect
   */
  isDropdownSelect?: boolean;
  /**
   * Функция от компонента BaseDropdown для передачи ей текущего ref-объекта
   */
  floatWrapperRef?: FloatWrapperRefType;
}

type ElementStyles = Pick<CSSStyleDeclaration, 'top' | 'left' | 'width'>;

const defaultRect = {
  top: 0,
  left: 0,
};

const FloatWrapper: React.FC<IFloatWrapperProps> = props => {
  const {
    children,
    padding = 16,
    nodeVertical = 'bottom',
    nodeHorizontal = 'left',
    modalVertical = 'top',
    modalHorizontal = 'left',
    offsetTop = 0,
    offsetLeft = 0,
    isOverflow = false,
    moveDetectorSettings = {},
    isCheckBorders = true,
    onClose = () => {},
    className,
    node = null,
    isOpen,
    isControlled,
    isMoveDetect,
    isSameWidth,
    isDropdownSelect,
    domNode = document.querySelector('body'),
    floatWrapperRef,
    extra,
  } = props;

  const popupRef = React.useRef<HTMLDivElement | null>(null);
  const scrollableEl = React.useRef<HTMLElement | null>(null);

  const isMounted = React.useRef<boolean>(false);

  const getStyles = React.useCallback(
    (rect: DOMRect | undefined) => {
      if (!rect) {
        return defaultRect;
      }

      const { top, left, width, height } = rect;

      const position = {
        top: checkVertical(top, height, nodeVertical, false),
        left: checkHorizontal(left, width, nodeHorizontal, false),
      };

      const { offsetWidth, offsetHeight } = popupRef.current!;

      if (offsetWidth || offsetHeight) {
        position.top = checkVertical(position.top, offsetHeight, modalVertical, true);
        position.left = checkHorizontal(position.left, offsetWidth, modalHorizontal, true);
      }

      if (!isCheckBorders) {
        return position;
      }

      // Внутри компонента DropdownSelect нам необходимы размеры рутового элемента, а не всплывающего.
      // https://tfs.securitycode.ru/tfs/Design/OPPI/_workitems/edit/4787
      const positionObject = checkBorders({
        position,
        padding,
        sizes: isDropdownSelect && node ? node.getBoundingClientRect() : { width: offsetWidth, height: offsetHeight },
      });

      return isSameWidth ? Object.assign(positionObject, { width }) : positionObject;
    },
    [
      isCheckBorders,
      isDropdownSelect,
      isSameWidth,
      modalHorizontal,
      modalVertical,
      node,
      nodeHorizontal,
      nodeVertical,
      padding,
    ],
  );

  const updateStyles = useEvent(() => {
    if (!(popupRef && popupRef.current)) {
      return;
    }
    const rect = node ? node.getBoundingClientRect() : undefined;

    const compStyles = getStyles(rect);
    compStyles.top += offsetTop;
    compStyles.left += offsetLeft;

    // multi-select-dropdown. Для реакции на изменение пропса isSameWidth
    popupRef.current.style.width = 'auto';

    const nextStyles: ElementStyles = Object.keys(compStyles).reduce(
      (prev, currentValue) => ({
        ...prev,
        [currentValue as keyof typeof compStyles]: `${compStyles[currentValue as keyof typeof compStyles]}px`,
      }),
      {} as ElementStyles,
    );

    Object.keys(nextStyles).forEach(key => {
      popupRef.current!.style[key as keyof typeof nextStyles] = nextStyles[key as keyof typeof nextStyles];
    });
  });

  const handleClickOutside = useEvent((event: React.MouseEvent) => {
    if (clickDetector.isElemContainClick(popupRef.current)) {
      return;
    }

    onClose(event);
  });

  /**
   * @todo:
   * Здесь правило линтера react-hooks/exhaustive-deps отключено,
   * т.к. есть необходимость вызывать этот код единоразово только при монтировании компонента
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  const moveDetector = React.useMemo(() => new MoveDetector({ callback: updateStyles, ...moveDetectorSettings }), []);

  React.useEffect(() => {
    clickDetector.addClickCallback(handleClickOutside);
    updateStyles();
    isMounted.current = true;
    return () => {
      clickDetector.removeClickCallback(handleClickOutside);
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * useLayoutEffect здесь используется, т.к. есть некоторые конфликты с тем,
   * что устанавливается z-index и остальные параметры стилей.
   * при использовании простого useEffect появляется визуальный баг,
   * когда элемент появляется внизу страницы, а затем встаёт на своё местоа
   */
  React.useLayoutEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (node && isMoveDetect) {
      moveDetector.setNode(node);
      moveDetector.start();
    } else {
      moveDetector.stop();
    }
    updateStyles();
  }, [isMoveDetect, moveDetector, node, updateStyles]);

  const createRef = (item: HTMLDivElement) => {
    popupRef.current = item;
    if (floatWrapperRef && item) {
      floatWrapperRef(popupRef.current);
    }
  };

  const getScrollParent = (nodeElement: HTMLElement | null): HTMLElement | null => {
    if (nodeElement === null) {
      return null;
    }
    if (nodeElement.scrollHeight > nodeElement.clientHeight) {
      return nodeElement;
    }

    return getScrollParent(nodeElement.parentElement);
  };

  const hideScrollBar = () => {
    scrollableEl.current = getScrollParent(node);

    if (scrollableEl && scrollableEl.current && isOverflow) {
      scrollableEl.current.classList.add(styles.overflow);
    }
  };

  const defaultScrollBar = () => {
    if (scrollableEl && scrollableEl.current && isOverflow) {
      scrollableEl.current.classList.remove(styles.overflow);
    }
    scrollableEl.current = null;
  };

  if (node && !scrollableEl) {
    hideScrollBar();
  }

  domNode?.classList.toggle(styles.portal, !!node);

  const classes = classNames(styles.popup, className, {
    [styles.hide]: isControlled ? !node || !isOpen : !node,
  });

  if (!node) {
    defaultScrollBar();
  }
  if (node) {
    return ReactDOM.createPortal(
      <div ref={createRef} className={classes} {...extra}>
        {children}
      </div>,
      domNode!,
    );
  }
  return null;
};

export default FloatWrapper;
