import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SkeletonBar } from '@sc-reactkit/helpers';
import { getButtonProps } from './utils';
import styles from './list.module.scss';

/**
 * Элемент списка
 */
class ListItem extends React.Component {
  onClick = event => {
    const { onClick, afterClick } = this.props;
    const isClickByItem = event.target === this.node;
    if (onClick && isClickByItem) {
      onClick(event);
      if (afterClick) {
        afterClick();
      }
    }
  };

  addProps = child => {
    if (!child) {
      return null;
    }
    const { disabled, onClick, afterClick } = this.props;
    const isText = child.type.IS_TEXT;
    const isContainer = child.type.IS_CONTAINER;
    const isSection = child.type.IS_SECTION;
    const other = {};
    if (!child.props.onClick && (isText || isContainer)) {
      other.onClick = onClick;
    }
    if (isText || isContainer || isSection) {
      other.afterClick = afterClick;
    }
    if (disabled) {
      other.disabled = disabled;
    }
    return React.cloneElement(child, { ...other });
  };

  render() {
    const { onClick, children, selected, className, style, disabled, isSkeleton, extra } = this.props;
    const isButton = typeof onClick === 'function';
    const buttonProps = getButtonProps(isButton && !disabled, { onClick: this.onClick });
    const itemClasses = classNames(styles.item, {
      [styles.selected]: selected,
      [styles.disabled]: disabled,
      [className]: className,
      [styles.skeletonItem]: isSkeleton,
    });

    return (
      <div
        className={itemClasses}
        style={style}
        {...buttonProps}
        ref={node => {
          this.node = node;
        }}
        {...extra}
      >
        {selected && <div className={styles.selectedBar} />}
        {isSkeleton ? (
          <SkeletonBar extraClasses={styles.skeletonSectionItem} />
        ) : (
          React.Children.map(children, this.addProps)
        )}
      </div>
    );
  }
}

ListItem.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Обработчик клика по элементу списка
   */
  onClick: PropTypes.func,
  /**
   * Действие после клика
   */
  afterClick: PropTypes.func,
  /**
   * skeleton-поведение
   */
  isSkeleton: PropTypes.bool,
  /**
   * Элемент выбран
   */
  selected: PropTypes.bool,
  /**
   * Элемент недоступен
   */
  disabled: PropTypes.bool,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ListItem;
