/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// fix ie11
import ReactResizeDetector from 'react-resize-detector/build/withPolyfill';
import { throttle } from 'throttle-debounce';

import { ThemeContext, themes } from 'sc-context';
import List from '@sc-reactkit/list';

import { LMS } from '@sc-reactkit/internal';

import TButton from './button';
import TDropdown from './dropdown';
import styles from './toolbar.module.scss';

const toolbarConfig = {
  dropdownWidth: 32,
};

/**
 * Локальная планель инструментов
 * @todo Add Readme
 */
class Toolbar extends Component {
  state = {
    hasOverlay: true,
  };

  /**
   * @public
   */
  onSearchToggle = () => {
    const { onSearchToggle } = this.props;
    const { isSearchVisible } = this.state;
    onSearchToggle(!isSearchVisible);
    this.setState({
      isSearchVisible: !isSearchVisible,
    });
  };

  /**
   * При инициализации отрабатывает два раза. На время, пока ожидаем актуальное значение ширины показываем оверлей.
   */
  // eslint-disable-next-line react/sort-comp
  callRerender = throttle(100, () => {
    this.setState({ hasOverlay: true });
    this.calculateVisibleItems();
  });

  // TODO: newThemeSvgReplace
  renderEllipsis = () => (
    <div className={styles.ellipsis}>
      <svg width="2" height="14" viewBox="0 0 2 14">
        <g id="Сгруппировать_10949" data-name="Сгруппировать 10949" transform="translate(-59.013 -34)">
          <rect width="2" height="2" rx="1" transform="translate(59.013 40)" />
          <rect width="2" height="2" rx="1" transform="translate(59.013 34)" />
          <rect width="2" height="2" rx="1" transform="translate(59.013 46)" />
        </g>
      </svg>
    </div>
  );

  itemsRef = node => {
    if (node) {
      this.node = node;
    }
  };

  /**
   * Метод рассчитывает количество видимых/скрытых элементов. Значения записываются в стейт.
   */
  calculateVisibleItems = () => {
    const { node } = this;

    const { width: visibleItemsSpace } = node.getBoundingClientRect();

    const domChildren = [...node.children];

    const domChildrenWidth = domChildren.map(child =>
      Math.ceil(child.dataset.width || child.getBoundingClientRect().width),
    );

    let visibleItemsCount = 0;
    domChildrenWidth.reduce((sumWidth, childWidth) => {
      if (sumWidth + childWidth < visibleItemsSpace - toolbarConfig.dropdownWidth) {
        visibleItemsCount += 1;
      }
      return sumWidth + childWidth;
    }, 0);
    const truncatedItemsCount = domChildren.length - visibleItemsCount;
    this.setState({ hasOverlay: false, visibleItemsCount, truncatedItemsCount });
  };

  render() {
    const { pinnedElements, size, searchComponent, onSearch, className, style, extra, children, ...other } = this.props;
    const { isSearchVisible, hasOverlay, visibleItemsCount, truncatedItemsCount } = this.state;
    const { theme, size: contextSize } = this.context;
    const rootClasses = classNames(styles.root, styles[size || contextSize], {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });
    const searchClasses = classNames(styles.search, {
      [styles.visible]: isSearchVisible,
    });

    const visibleChildren = React.Children.map(children, (child, index) =>
      index >= visibleItemsCount ? React.cloneElement(child, { isHidden: true }) : child,
    );

    const truncatedChildren = React.Children.map(children, (child, index) =>
      index >= visibleItemsCount ? React.cloneElement(child, { isTruncated: true }) : undefined,
    );

    return (
      <ReactResizeDetector handleWidth onResize={this.callRerender} {...other}>
        <div className={rootClasses} style={style} {...extra}>
          {hasOverlay && <div className={styles.overlay} />}
          <div className={styles.items} ref={this.itemsRef}>
            {visibleChildren}
          </div>
          {truncatedItemsCount > 0 && (
            <div className={styles.dropdown}>
              <TDropdown
                name="toolbar_dropdown"
                isTruncateWrapper
                caret={false}
                tButtonChildren={this.renderEllipsis()}
              >
                <List>{truncatedChildren}</List>
              </TDropdown>
            </div>
          )}
          {pinnedElements}
          {searchComponent && (
            <React.Fragment>
              <TButton
                icon="base_search"
                onClick={this.onSearchToggle}
                extra={{ 'data-anchor': 'toolbar-button-search-show' }}
              />
              <div className={searchClasses}>
                <div className={styles.component}>{searchComponent}</div>
                {onSearch && (
                  <TButton icon="base_search" onClick={onSearch} extra={{ 'data-anchor': 'toolbar-button-search' }} />
                )}
                <TButton
                  icon="base-actions_close"
                  onClick={this.onSearchToggle}
                  extra={{ 'data-anchor': 'toolbar-button-search-hide' }}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </ReactResizeDetector>
    );
  }
}

Toolbar.contextType = ThemeContext;

Toolbar.defaultProps = {
  onSearchToggle: () => {},
};

Toolbar.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Элементы, которые не скрываются при ресайзе тулбара
   */
  pinnedElements: PropTypes.element,
  /**
   * Компонент поиска
   */
  searchComponent: PropTypes.element,
  /**
   * Обработчик кнопки поиска. Если присутствует, то показывается кнопка поиска
   */
  onSearch: PropTypes.func,
  /**
   * Срабатывает при показе/скрытии поиска
   * @param {Boolean} isSearchVisible - видна ли панель поиска
   */
  onSearchToggle: PropTypes.func,
  /**
   * Размер
   */
  size: LMS,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Toolbar;
