import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import classNames from 'classnames';
import IExtra from '../interfaces/extra';
import ISpread from '../interfaces/spread';
import IExtendClassProps from '../interfaces/extend-class-props';
import { CSSObject } from '../types';

export interface ISwitchBaseProps extends IExtra, ISpread, IExtendClassProps {
  styles: CSSObject;
  inputType: string;
  /**
   * Кастомный инлайн-стиль
   */
  customClasses: string;
  disabled?: boolean;
  name?: number | string;
  checked?: boolean;
  defaultChecked?: boolean;
  value?: string;
  indeterminate?: boolean;
  getIcon?: (checked?: boolean, indeterminate?: boolean) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLLabelElement>;
}

/**
 * Поле выбора
 */
const SwitchBase: React.FC<ISwitchBaseProps> = props => {
  const {
    name,
    checked,
    defaultChecked,
    styles,
    inputType,
    disabled,
    customClasses,
    value,
    onChange,
    onClick,
    getIcon,
    indeterminate,
    children,
    className,
    style,
    extra,
    ...other
  } = props;

  const controlled = checked !== undefined;

  const ref = React.useRef<HTMLInputElement | null>(null);
  const [internalChecked, setChecked] = React.useState<boolean | undefined>();

  React.useEffect(() => {
    if (!controlled) {
      setChecked(defaultChecked);
    }
  }, [controlled]);

  const onKeyPress = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    ref?.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!controlled) {
      setChecked(event.target.checked);
    }

    onChange?.(event);
  };

  const calcChecked = controlled ? checked : internalChecked;

  const labelClassName = classNames(customClasses, {
    [styles.disabled]: disabled,
    [styles.checked]: calcChecked || indeterminate,
    className,
  });

  const icon = getIcon && getIcon(calcChecked, indeterminate);

  /* eslint-disable */
  return (
    <label
      className={labelClassName}
      style={style}
      role="button"
      tabIndex={disabled ? -1 : 0}
      onKeyPress={onKeyPress}
      onClick={onClick}
      {...extra}
    >
      <input
        disabled={disabled}
        value={value}
        checked={calcChecked}
        name={name ? String(name) : undefined}
        type={inputType}
        className={styles.input}
        onChange={handleChange}
        tabIndex={disabled ? -1 : 0}
        onClick={e => e.stopPropagation()}
        ref={ref}
        {...other}
      />
      {icon}
      {children}
    </label>
  );
};

export default SwitchBase;
