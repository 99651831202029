import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeContext, themes } from 'sc-context';
import styles from './label.module.scss';

export default class Label extends React.Component {
  render() {
    const { children, className, style, extra } = this.props;
    const { theme } = this.context;
    const titleClasses = classNames(styles.title, {
      [styles[theme]]: theme !== themes.default,
      [className]: className,
    });

    return (
      <p className={titleClasses} style={style} {...extra}>
        {children}
      </p>
    );
  }
}

Label.contextType = ThemeContext;

Label.propTypes = {
  /**
   * Кастомный инлайн-стиль
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Кастомное название стиля
   */
  className: PropTypes.string,
  /**
   * Произвольные атрибуты
   */
  extra: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
